/* External dependencies */
import gql from 'graphql-tag';

export const deleteServiceMutation = gql`
  mutation deleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      statusMessage
    }
  }
`;

export const deleteCategoryMutation = gql`
  mutation deleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      statusMessage
    }
  }
`;
