/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { BlockServiceAction, BlockServiceActionTypes } from './actions';

export interface BlockServiceState {
  error?: Error;
  isBlockServiceSuccess?: boolean;
  loading?: boolean;
}

export const initialBlockServiceState: BlockServiceState = {
  isBlockServiceSuccess: false,
};

export default function blockServiceReducer(
  state = initialBlockServiceState,
  action: BlockServiceAction,
) {
  switch (action.type) {
    case BlockServiceActionTypes.BLOCK_SERVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case BlockServiceActionTypes.BLOCK_SERVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isBlockServiceSuccess: { $set: true },
      });

    case BlockServiceActionTypes.BLOCK_SERVICE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case BlockServiceActionTypes.RESET_BLOCK_SERVICE_STATE:
      return update(state, { $set: initialBlockServiceState });

    default:
      return state;
  }
}
