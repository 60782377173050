export interface Client {
  id: string;
  name: string;
  registrationDate: string;
  uiCustomization: uiCustomization;
  callbackUrls: string[];
}

export interface uiCustomization {
  imageUrl: string;
}

export enum CallbackUrlStatus {
  VALIDATED = 'VALIDATED',
  NOT_VALIDATED = 'NOT_VALIDATED',
  ERROR = 'ERROR',
}
