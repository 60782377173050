module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SMART","short_name":"SMART","description":" «SMART»","lang":"ru","start_url":"/","background_color":"#545ee0","theme_color":"#545ee0","display":"fullscreen","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b4c6aad5bf33a1ce34d544abf72132ec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"_","debug":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ky","ru"],"redirect":false,"defaultLanguage":"ru","siteUrl":"http://localhost:8000/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"blue","parent":"body","template":"\n        <div class=\"bar\" role=\"progressbar\" aria-valuemin=\"10\" aria-valuemax=\"15\">\n          <div class=\"peg\"></div>\n        </div>\n        <div class=\"custom-spinner\" role=\"progressbar\" aria-valuemin=\"10\" aria-valuemax=\"15\">\n          <div class=\"custom-spinner-icon nprogress-icon\"></div>\n        </div>\n      ","minimum":0.4},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
