/* Local dependencies */
import { UpdateServiceProviderFields, UpdateServiceProvider, ServiceProvider } from '../../../utils/types';

export enum UpdateServiceProviderActionTypes {
  GET_SERVICE_PROVIDER_REQUEST = 'GET_SERVICE_PROVIDER_REQUEST',
  GET_SERVICE_PROVIDER_SUCCESS = 'GET_SERVICE_PROVIDER_SUCCESS',
  GET_SERVICE_PROVIDER_ERROR = 'GET_SERVICE_PROVIDER_ERROR',
  UPDATE_SERVICE_PROVIDER_REQUEST = 'UPDATE_SERVICE_PROVIDER_REQUEST',
  UPDATE_SERVICE_PROVIDER_SUCCESS = 'UPDATE_SERVICE_PROVIDER_SUCCESS',
  UPDATE_SERVICE_PROVIDER_ERROR = 'UPDATE_SERVICE_PROVIDER_ERROR',
  RESET_ERROR_UPDATE_SERVICE_PROVIDER = 'RESET_ERROR_UPDATE_SERVICE_PROVIDER',
  RESET_UPDATE_SERVICE_PROVIDER_STATE = 'RESET_UPDATE_SERVICE_PROVIDER_STATE',
  ADD_UPDATE_SERVICE_PROVIDER_FIELDS = 'ADD_UPDATE_SERVICE_PROVIDER_FIELDS',
  ADD_BACK_PATH_UPDATE_SERVICE_PROVIDER = 'ADD_BACK_PATH_UPDATE_SERVICE_PROVIDER',
}

export interface GetServiceProviderRequest {
  type: UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_REQUEST;
  id: string;
}

export interface GetServiceProviderSuccess {
  type: UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_SUCCESS;
  serviceProvider: ServiceProvider;
}

export interface GetServiceProviderError {
  type: UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_ERROR;
  error: Error;
}

export interface UpdateServiceProviderRequest {
  type: UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST;
  serviceProvider: UpdateServiceProvider;
}

export interface UpdateServiceProviderSuccess {
  type: UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_SUCCESS;
  serviceProvider: UpdateServiceProvider;
}

export interface UpdateServiceProviderError {
  type: UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_ERROR;
  error: Error;
}

export interface ResetErrorUpdateServiceProvider {
  type: UpdateServiceProviderActionTypes.RESET_ERROR_UPDATE_SERVICE_PROVIDER;
}

export interface ResetUpdateServiceProviderState {
  type: UpdateServiceProviderActionTypes.RESET_UPDATE_SERVICE_PROVIDER_STATE;
}

export interface AddServiceProviderFields {
  type: UpdateServiceProviderActionTypes.ADD_UPDATE_SERVICE_PROVIDER_FIELDS;
  fields: UpdateServiceProviderFields;
}

export interface AddBackPathUpdateServiceProvider {
  type: UpdateServiceProviderActionTypes.ADD_BACK_PATH_UPDATE_SERVICE_PROVIDER;
  backPath: string;
}

export type UpdateServiceProviderAction =
  | GetServiceProviderRequest
  | GetServiceProviderSuccess
  | GetServiceProviderError
  | UpdateServiceProviderRequest
  | UpdateServiceProviderSuccess
  | UpdateServiceProviderError
  | ResetErrorUpdateServiceProvider
  | ResetUpdateServiceProviderState
  | AddServiceProviderFields
  | AddBackPathUpdateServiceProvider;

export function getServiceProviderRequest(id: string): GetServiceProviderRequest {
  return {
    type: UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_REQUEST,
    id,
  };
}

export function getServiceProviderSucceeded(serviceProvider: ServiceProvider): GetServiceProviderSuccess {
  return {
    type: UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_SUCCESS,
    serviceProvider,
  };
}

export function getServiceProviderFailed(error: Error): GetServiceProviderError {
  return {
    type: UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_ERROR,
    error,
  };
}

export function updateServiceProviderRequest(serviceProvider: UpdateServiceProvider): UpdateServiceProviderRequest {
  return {
    type: UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST,
    serviceProvider,
  };
}

export function updateServiceProviderSucceeded(serviceProvider: UpdateServiceProvider): UpdateServiceProviderSuccess {
  return {
    type: UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_SUCCESS,
    serviceProvider,
  };
}

export function updateServiceProviderFailed(error: Error): UpdateServiceProviderError {
  return {
    type: UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_ERROR,
    error,
  };
}

export function resetErrorUpdateServiceProvider(): ResetErrorUpdateServiceProvider {
  return {
    type: UpdateServiceProviderActionTypes.RESET_ERROR_UPDATE_SERVICE_PROVIDER,
  };
}

export function resetUpdateServiceProviderState(): ResetUpdateServiceProviderState {
  return {
    type: UpdateServiceProviderActionTypes.RESET_UPDATE_SERVICE_PROVIDER_STATE,
  };
}

export function addUpdateServiceProviderFields(fields: UpdateServiceProviderFields): AddServiceProviderFields {
  return {
    type: UpdateServiceProviderActionTypes.ADD_UPDATE_SERVICE_PROVIDER_FIELDS,
    fields,
  };
}

export function addBackPathUpdateServiceProvider(backPath: string): AddBackPathUpdateServiceProvider {
  return {
    type: UpdateServiceProviderActionTypes.ADD_BACK_PATH_UPDATE_SERVICE_PROVIDER,
    backPath,
  };
}
