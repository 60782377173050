/* External dependencies */
import { debounceTime, filter, switchMap, map, catchError } from 'rxjs/operators';
import { from, of, Observable } from 'rxjs';

/* Local dependencies */
import { getClient } from '../../../clients/demirbank';
import {
  ListTransactions,
  ListTransactionsAction,
  ListTransactionsActionTypes,
  listTransactionsFailed,
  listTransactionsSucceeded,
} from './actions';
import { listPaymentsQuery } from './queries';

export default function listTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactions(
        action,
        state$.value.transactions?.size,
        state$.value.transactions?.startDate,
        state$.value.transactions?.endDate,
      ).pipe(
        map((result: any) => {
          const { total, payments } = result;

          return listTransactionsSucceeded(total, payments); // Dispatch success action
        }),
        catchError((error) => of(listTransactionsFailed(error))), // Dispatch failure action
      ),
    ),
  );
}

function listTransactions(
  { currentPage, filter, searchString }: ListTransactions,
  size: number,
  startDate: Date,
  endDate: Date,
) {
  return new Observable((observer) => {
    getClient().then((graphQLClient) => {
      console.log(startDate, endDate);

      const apolloObservable = graphQLClient.watchQuery({
        query: listPaymentsQuery,
        variables: {
          input: {
            filter,
            from: (currentPage - 1) * size,
            query: searchString,
            size,
            startDate: new Date(startDate).getTime(),
            endDate: new Date(endDate).getTime(),
          },
        },
      });

      const subscription = apolloObservable.subscribe({
        next(result) {
          console.log('result', result);

          if (!result.data) {
            observer.next({ total: 0, payments: [] });

            return;
          }

          const { total, payments } = result.data.listPayments;

          observer.next({ total, payments }); // Emit result
          observer.complete(); // Complete the observable
        },
        error(err) {
          observer.error(err); // Pass the error to the observable
        },
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
}
