/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../../clients/demirbank';
import { cleanPayload } from '../../../../common/helpers';
import { ServiceProvider } from '../../../utils/types';
import {
  getServiceProviderFailed,
  GetServiceProviderRequest,
  getServiceProviderSucceeded,
  UpdateServiceProviderAction,
  UpdateServiceProviderActionTypes,
  updateServiceProviderFailed,
  UpdateServiceProviderRequest,
  updateServiceProviderSucceeded,
} from './actions';
import { updateServiceProviderMutation } from './mutation';
import { getServiceProviderQuery } from './queries';

export function getServiceProviderEpic(action$) {
  return action$.pipe(
    filter(
      (action: UpdateServiceProviderAction) =>
        action.type === UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_REQUEST,
    ),
    switchMap((action: GetServiceProviderRequest) =>
      getServiceProvider(action).then(getServiceProviderSucceeded).catch(getServiceProviderFailed),
    ),
  );
}

export async function getServiceProvider(action: GetServiceProviderRequest): Promise<ServiceProvider> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { getServiceProvider },
  } = await graphQLClient.query({
    query: getServiceProviderQuery,
    variables: {
      input: { id },
    },
  });

  return getServiceProvider as ServiceProvider;
}

export function updateServiceProviderEpic(action$) {
  return action$.pipe(
    filter(
      (action: UpdateServiceProviderAction) =>
        action.type === UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST,
    ),
    switchMap((action: UpdateServiceProviderRequest) =>
      updateServiceProvider(action).then(updateServiceProviderSucceeded).catch(updateServiceProviderFailed),
    ),
  );
}

export async function updateServiceProvider(action: UpdateServiceProviderRequest): Promise<ServiceProvider> {
  const graphQLClient = await getClient();
  const { serviceProvider } = action;

  const {
    data: { updateServiceProvider },
  } = await graphQLClient.mutate({
    mutation: updateServiceProviderMutation,
    variables: {
      input: cleanPayload(serviceProvider),
    },
  });

  return updateServiceProvider as ServiceProvider;
}
