exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-service-providers-create-service-provider-index-tsx": () => import("./../../../src/pages/admin/service-providers/create-service-provider/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-providers-create-service-provider-index-tsx" */),
  "component---src-pages-admin-service-providers-index-tsx": () => import("./../../../src/pages/admin/service-providers/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-providers-index-tsx" */),
  "component---src-pages-admin-service-providers-update-service-provider-index-tsx": () => import("./../../../src/pages/admin/service-providers/update-service-provider/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-providers-update-service-provider-index-tsx" */),
  "component---src-pages-admin-services-create-service-index-tsx": () => import("./../../../src/pages/admin/services/create-service/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-create-service-index-tsx" */),
  "component---src-pages-admin-services-index-tsx": () => import("./../../../src/pages/admin/services/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-index-tsx" */),
  "component---src-pages-admin-services-update-service-index-tsx": () => import("./../../../src/pages/admin/services/update-service/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-update-service-index-tsx" */),
  "component---src-pages-admin-transactions-index-tsx": () => import("./../../../src/pages/admin/transactions/index.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-index-tsx" */),
  "component---src-pages-admin-users-create-user-index-tsx": () => import("./../../../src/pages/admin/users/create-user/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-create-user-index-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-admin-users-update-user-index-tsx": () => import("./../../../src/pages/admin/users/update-user/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-update-user-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-change-password-index-tsx": () => import("./../../../src/pages/login/change-password/index.tsx" /* webpackChunkName: "component---src-pages-login-change-password-index-tsx" */),
  "component---src-pages-login-forgot-password-index-tsx": () => import("./../../../src/pages/login/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-login-forgot-password-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-verify-code-index-tsx": () => import("./../../../src/pages/login/verify-code/index.tsx" /* webpackChunkName: "component---src-pages-login-verify-code-index-tsx" */)
}

