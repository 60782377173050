/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/demirbank';
import { CreateUserAction, CreateUserActionTypes, createUserFailedAction, createUserSucceeded, User } from './actions';
import { DuplicateUserException } from './exceptions';
import { adminCreateUserMutation } from './mutations';

export default function createUserEpic(action$) {
  return action$.pipe(
    filter((action: CreateUserAction) => action.type === CreateUserActionTypes.CREATE_USER_REQUEST),
    switchMap((action: CreateUserAction) =>
      createUser(action)
        .then(createUserSucceeded)
        .catch((error) => {
          const [{ errorType, message }] = error.graphQLErrors;

          if (errorType === 'UsernameExistsException') {
            return createUserFailedAction(new DuplicateUserException());
          }

          return createUserFailedAction(new Error(message));
        }),
    ),
  );
}

export async function createUser(action): Promise<User> {
  const graphQLClient = await getClient();

  const {
    data: { adminCreateUser },
  } = await graphQLClient.mutate({
    mutation: adminCreateUserMutation,
    variables: { input: action.user },
  });

  return adminCreateUser as User;
}
