/* External dependencies */
import { filter } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/demirbank';
import { UpdateUserInput } from '../../createUser/redux/actions';
import {
  GetUser,
  getUserFailed,
  getUserSucceeded,
  UserActions,
  UserActionTypes,
  UpdateUser,
  updateUserSuccess,
  updateUserFailure,
} from './actions';
import { updateUserMutation } from './mutations';
import { getUserQuery } from './queries';

export function getUserEpic(action$) {
  return action$.pipe(
    filter((action: UserActions) => action.type === UserActionTypes.GET_USER_REQUEST),
    switchMap((action: GetUser) => getUser(action).then(getUserSucceeded).catch(getUserFailed)),
  );
}

export async function getUser({ id }: GetUser): Promise<UpdateUserInput> {
  const graphqlQLClient = await getClient();

  const {
    data: { getUser },
  } = await graphqlQLClient.query({
    query: getUserQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getUser as UpdateUserInput;
}

export function updateUserEpic(action$) {
  return action$.pipe(
    filter((action: UserActions) => action.type === UserActionTypes.UPDATE_USER_REQUEST),
    switchMap((action: UpdateUser) =>
      updateUserDetails(action)
        .then(updateUserSuccess)
        .catch((error) => {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            const [{ message }] = error.graphQLErrors;

            return updateUserFailure(new Error(message));
          }

          return updateUserFailure(error);
        }),
    ),
  );
}

export async function updateUserDetails({ user }): Promise<UpdateUserInput> {
  const graphQLClient = await getClient();
  const {
    data: { updateUser },
  } = await graphQLClient.mutate({
    mutation: updateUserMutation,
    variables: {
      input: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        status: user.status,
      },
    },
  });

  return updateUser as UpdateUserInput;
}
