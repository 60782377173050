/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DeleteServiceAction, DeleteServiceActionTypes } from './actions';

export interface DeleteServiceState {
  error?: Error;
  isConfirmationPopupDeleteService?: boolean;
  isSuccess: boolean;
  loading: boolean;
  statusMessage?: string;
}

const DeleteServiceInitialState: DeleteServiceState = {
  isConfirmationPopupDeleteService: false,
  isSuccess: false,
  loading: false,
};

export default function deleteServiceReducer(state = DeleteServiceInitialState, action: DeleteServiceAction) {
  switch (action.type) {
    case DeleteServiceActionTypes.DELETE_SERVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case DeleteServiceActionTypes.DELETE_SERVICE_SUCCESS:
      return update(state, {
        isConfirmationPopupDeleteService: { $set: false },
        isSuccess: { $set: true },
        loading: { $set: false },
        statusMessage: { $set: action.statusMessage },
      });

    case DeleteServiceActionTypes.DELETE_SERVICE_ERROR:
      return update(state, {
        isConfirmationPopupDeleteService: { $set: false },
        loading: { $set: false },
        error: { $set: action.error },
      });

    case DeleteServiceActionTypes.DELETE_CATEGORY_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case DeleteServiceActionTypes.DELETE_CATEGORY_SUCCESS:
      return update(state, {
        isConfirmationPopupDeleteService: { $set: false },
        isSuccess: { $set: true },
        loading: { $set: false },
        statusMessage: { $set: action.statusMessage },
      });

    case DeleteServiceActionTypes.DELETE_CATEGORY_ERROR:
      return update(state, {
        isConfirmationPopupDeleteService: { $set: false },
        loading: { $set: false },
        error: { $set: action.error },
      });

    case DeleteServiceActionTypes.DELETE_SERVICE_RESET:
      return update(state, { $set: DeleteServiceInitialState });

    case DeleteServiceActionTypes.SHOW_CONFIRMATION_DELETE_SERVICE_POPUP:
      return update(state, {
        isConfirmationPopupDeleteService: { $set: true },
      });

    case DeleteServiceActionTypes.HIDE_CONFIRMATION_DELETE_SERVICE_POPUP:
      return update(state, {
        isConfirmationPopupDeleteService: { $set: false },
      });

    default:
      return state;
  }
}
