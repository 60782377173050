/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Service } from '../utils/types';
import { ListServicesAction, ListServicesActionTypes, ListServicesFilter } from './actions';

export interface ServicesState {
  currentPage: number;
  error?: Error;
  filter?: ListServicesFilter;
  loading?: boolean;
  locale?: string;
  pressedService?: Service | null;
  services: Service[];
  searchString: string;
  selectedServices: Array<any>;
  size: number;
  total: number;
}

const size: number = 20;

export const initialServicesState: ServicesState = {
  currentPage: 1,
  loading: true,
  pressedService: null,
  services: [],
  searchString: '',
  selectedServices: [],
  size,
  total: 0,
};

export default function servicesReducer(state = initialServicesState, action: ListServicesAction) {
  switch (action.type) {
    case ListServicesActionTypes.LIST_SERVICES_REQUEST:
      const { currentPage, filter, searchString, size } = action;

      return update(state, {
        $unset: ['error'],
        filter: { $set: filter },
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        services: { $set: [] },
        selectedServices: { $set: initialServicesState.selectedServices },
        size: { $set: size },
      });

    case ListServicesActionTypes.LIST_SERVICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        services: { $set: action.services },
        total: { $set: action.total },
      });

    case ListServicesActionTypes.LIST_SERVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListServicesActionTypes.SELECT_SERVICE:
      return update(state, {
        selectedServices: { $set: [action.service] },
      });

    case ListServicesActionTypes.RESET_SELECTED_SERVICE:
      return update(state, {
        selectedServices: { $set: initialServicesState.selectedServices },
      });

    case ListServicesActionTypes.RESET_SERVICES_STATE:
      return initialServicesState;

    case ListServicesActionTypes.PRESS_SERVICE:
      return update(state, {
        pressedService: { $set: action.service },
      });

    case ListServicesActionTypes.RESET_PRESS_SERVICE:
      return update(state, {
        pressedService: { $set: initialServicesState.pressedService },
      });

    case ListServicesActionTypes.GET_LOCALE:
      return update(state, {
        locale: { $set: action.locale },
      });

    default:
      return state;
  }
}
