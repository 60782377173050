/* Local dependencies */
import { UIException } from '../../../../../redux/exceptions';

export enum DeleteServiceProviderActionTypes {
  DELETE_SERVICE_PROVIDER_REQUEST = 'DELETE_SERVICE_PROVIDER_REQUEST',
  DELETE_SERVICE_PROVIDER_SUCCESS = 'DELETE_SERVICE_PROVIDER_SUCCESS',
  DELETE_SERVICE_PROVIDER_ERROR = 'DELETE_SERVICE_PROVIDER_ERROR',
  DELETE_SERVICE_PROVIDER_RESET = 'DELETE_SERVICE_PROVIDER_RESET',
  HIDE_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP = 'HIDE_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP',
  SHOW_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP = 'SHOW_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP',
}

export type DeleteServiceProviderAction =
  | DeleteServiceProviderRequest
  | DeleteServiceProviderSuccess
  | DeleteServiceProviderError
  | DeleteServiceProviderReset
  | HideConfirmationDeleteServiceProviderPopup
  | ShowConfirmationDeleteServiceProviderPopup;

export interface DeleteServiceProviderRequest {
  type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_REQUEST;
  id: string;
}

export interface DeleteServiceProviderSuccess {
  type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_SUCCESS;
  statusMessage: string;
}

export interface DeleteServiceProviderError {
  type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_ERROR;
  error: UIException;
}

export interface DeleteServiceProviderReset {
  type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_RESET;
}

export interface ShowConfirmationDeleteServiceProviderPopup {
  type: DeleteServiceProviderActionTypes.SHOW_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP;
}

export interface HideConfirmationDeleteServiceProviderPopup {
  type: DeleteServiceProviderActionTypes.HIDE_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP;
}

export function deleteServiceProviderRequest(id: string): DeleteServiceProviderRequest {
  return {
    type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_REQUEST,
    id,
  };
}

export function deleteServiceProviderSucceeded(statusMessage: string): DeleteServiceProviderSuccess {
  return {
    type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_SUCCESS,
    statusMessage,
  };
}

export function deleteServiceProviderFailed(error: UIException): DeleteServiceProviderError {
  return {
    type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_ERROR,
    error,
  };
}

export function deleteServiceProviderReset(): DeleteServiceProviderReset {
  return {
    type: DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_RESET,
  };
}

export function showConfirmationDeleteServiceProviderPopup(): ShowConfirmationDeleteServiceProviderPopup {
  return {
    type: DeleteServiceProviderActionTypes.SHOW_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP,
  };
}

export function hideConfirmationDeleteServiceProviderPopup(): HideConfirmationDeleteServiceProviderPopup {
  return {
    type: DeleteServiceProviderActionTypes.HIDE_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP,
  };
}
