/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/demirbank';
import { cleanPayload, removeSpaces } from '../../../common/helpers';
import { cleanService } from '../../utils/helper';
import { Service } from '../../utils/types';
import { CreateServiceAction, CreateServiceActionTypes, createServiceFailed, createServiceSucceeded } from './actions';
import { createServiceMutation } from './mutation';

export default function createServiceEpic(action$) {
  return action$.pipe(
    filter((action: CreateServiceAction) => action.type === CreateServiceActionTypes.CREATE_SERVICE_REQUEST),
    switchMap((action: CreateServiceAction) =>
      createService(action)
        .then(createServiceSucceeded)
        .catch((error) => createServiceFailed(error)),
    ),
  );
}

export async function createService(action): Promise<Service> {
  const graphQLClient = await getClient();
  const { service } = action;

  const serviceInput = cleanPayload(service);

  const input = cleanService(removeSpaces(serviceInput));

  if (input?.logo?.url) {
    input.logo.url = input.logo.url.replace(/^data:image\/[a-z]+;base64,/, '');
  }

  const {
    data: { createService },
  } = await graphQLClient.mutate({
    mutation: createServiceMutation,
    variables: {
      input,
    },
  });

  return createService as Service;
}
