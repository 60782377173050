/* External dependencies */
import { NavigateFn } from '@reach/router';
import { Text } from 'atomize';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import { Locale } from '../../../locales/locales';
import { getUserRole } from '../../common/helpers';
import { UserRole } from '../../common/roles';
import { SessionUser } from '../../login/redux/actions';
import {
  CreateServiceProvider,
  CreateServiceProviderInput,
  Currency,
  DistributionFees,
  DynamicFeeType,
  FeesTypes,
  Service,
  ServiceProvider,
  ServiceProviderValueFields,
  ServiceStatus,
} from './types';

export function getManagerFields(language) {
  const { t } = useTranslation();
  let name = getNameField(language);

  return [
    {
      field: 'logo',
      title: t('logo'),
    },
    name,
    {
      field: 'popularityScore',
      title: t('popularityScore'),
    },
    {
      field: 'statusService',
      title: t('status'),
    },
  ];
}

export function getManagerServiceProvidersFields(language) {
  const { t } = useTranslation();

  let name = getNameField(language);

  return [name, { field: 'status', title: t('status') }];
}

function getNameField(language: string) {
  const { t } = useTranslation();

  switch (language) {
    case Locale.RU:
      return {
        field: 'name_ru',
        title: t('name_ru'),
      };
    case Locale.KY:
      return {
        field: 'name_ky',
        title: t('name_ky'),
      };
    case Locale.EN:
      return {
        field: 'name_en',
        title: t('name_en'),
      };
    default:
      return {
        field: 'name_ru',
        title: t('name_ru'),
      };
  }
}

export function getServiceStatusColor(status) {
  let statusColor = {
    bgColor: '',
    border: '',
    textColor: '',
  };

  switch (status) {
    case ServiceStatus.DISABLED:
      return (statusColor = {
        bgColor: 'pending_bg',
        textColor: 'pending_text_color',
        border: '#FFAA00',
      });
    case ServiceStatus.ENABLED:
      return (statusColor = {
        bgColor: 'succeeded_bg',
        textColor: 'succeeded_text_color',
        border: '#00D68F',
      });
    case ServiceStatus.INACTIVE:
      return (statusColor = {
        bgColor: 'failed_bg',
        textColor: 'failed_text_color',
        border: '#FF3D71',
      });

    default:
      return statusColor;
  }
}

export function getStatus(status: string) {
  const colorObj = getServiceStatusColor(status);

  return (
    <Text
      tag='span'
      bg={colorObj?.bgColor}
      textColor={colorObj?.textColor}
      p='2px 10px'
      rounded='lg'
      d='inline-block'
      style={{ border: `1px solid ${colorObj?.border}` }}
      className='service-status'
    >
      <Trans>{status}</Trans>
    </Text>
  );
}

export enum ActionsServicesMenuList {
  BLOCK = 'BLOCK',
  ACTIVATE = 'ACTIVATE',
  EDIT = 'EDIT',
  CREATESERVICE = 'createService',
  DELETE = 'deleteService',
}

export enum ActionsServiceProvidersMenuList {
  CREATE_SERVICE_PROVIDER = 'createServiceProvider',
  BLOCK = 'BLOCK',
  ACTIVATE = 'ACTIVATE',
}

export const serviceStatuses = [ServiceStatus.ENABLED, ServiceStatus.DISABLED];

export const listCurrencies: Currency[] = [Currency.KGS, Currency.RUB, Currency.USD];

export const feesTypes: FeesTypes[] = [FeesTypes.FLAT_FEE, FeesTypes.FLAT_FEE_PERCENT, FeesTypes.DYNAMIC_FEE_STEPS];

export const servicesFilters = [
  {
    label: 'ALL',
    value: JSON.stringify('ALL'),
  },
  {
    label: 'ACTIVEPLURAL',
    value: JSON.stringify(ServiceStatus.ENABLED),
  },
  {
    label: 'BLOCKPLURAL',
    value: JSON.stringify(ServiceStatus.DISABLED),
  },
  {
    label: 'INACTIVEPLURAL',
    value: JSON.stringify(ServiceStatus.INACTIVE),
  },
];

export enum ServiceTabList {
  SERVICE_MUTATION = 'SERVICE_MUTATION',
  SERVICE_CHECK = 'SERVICE_CHECK',
}

export const serviceTabList = [ServiceTabList.SERVICE_MUTATION, ServiceTabList.SERVICE_CHECK];

export function getUserSubMenuLinks(currentUser: SessionUser) {
  const userRole = getUserRole(currentUser);

  switch (userRole) {
    case UserRole.MANAGER:
      return getManagerSubLinks();
    case UserRole.ADMIN:
      return getManagerSubLinks();
    default:
      throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
  }
}

export function getManagerSubLinks() {
  const sublinks = [
    {
      key: 'stepOneCreateService',
      menuItem: 'stepOneCreateService',
    },
    {
      key: 'stepTwoCreateServiceInformation',
      menuItem: 'stepTwoCreateServiceInformation',
    },
    {
      key: 'stepThreeCreateServiceInfoCard',
      menuItem: 'stepThreeCreateServiceInfoCard',
    },
  ];

  return sublinks;
}

export function returnNameService(
  language: string,
  obj: Service | ServiceProvider | CreateServiceProvider | CreateService,
) {
  switch (language) {
    case Locale.RU:
      return obj?.name_ru;
    case Locale.KY:
      return obj?.name_ky;
    case Locale.EN:
      return obj?.name_en;
    default:
      return;
  }
}

export enum ServicesPaths {
  SERVICES = '/admin/services',
  CREATE_SERVICE = '/admin/services/create-service',
  UPDATE_SERVICE = '/admin/services/update-service/',
  SERVICE_PROVIDERS = '/admin/service-providers',
  CREATE_SERVICE_RPOVIDER = '/admin/services/create-service-provider',
  ACQUIRING_SERVICES = '/acquiring/services/',
}

export function clickBackNavService(submenuItem: string, onClickNextPage: Function, navigate: NavigateFn) {
  switch (submenuItem) {
    case ServiceTabList.SERVICE_MUTATION:
      navigate('/admin/services');
      break;
    case ServiceTabList.SERVICE_CHECK:
      return onClickNextPage(ServiceTabList.SERVICE_MUTATION);
    default:
      return '';
  }
}

export function convertToCamelCase(str) {
  const words = str.split(' ');

  // Convert the first word to lowercase
  let outputString = words[0].toLowerCase();

  // Capitalize the first letter of each subsequent word
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
    outputString += capitalizedWord;
  }

  return outputString;
}

export function getErrorTranslatedMessage(errorMessage: string) {
  const errorsMapping = {
    'Not Authorized to access updateCategory on type Mutation': 'UnauthorizedToDeleteCategory',
  };

  if (errorsMapping.hasOwnProperty(errorMessage)) {
    return <Trans>{errorsMapping[errorMessage]}</Trans>;
  }

  return errorMessage;
}

export function getBooleanForButton({ item, index }, service) {
  let isAddServiceProvider: boolean;

  if (item?.fees?.dynamicFeeSteps) {
    item?.fees?.dynamicFeeSteps?.forEach((value) => {
      isAddServiceProvider =
        item?.id &&
        !item?.serviceProviderError &&
        index === service?.serviceProviders?.length - 1 &&
        (value.fee.flatFee?.amount || value.fee.flatFeePercent) &&
        value.from.amount &&
        !(value.flatFeeAmountError || value.flatFeePercentError || value.fromAmountError);
    });
  } else {
    isAddServiceProvider =
      item?.id &&
      !item?.serviceProviderError &&
      index === service?.serviceProviders?.length - 1 &&
      (item?.fees?.flatFee?.amount || item?.fees?.flatFeePercent) &&
      !(item?.fees?.amountError || item?.fees?.flatFeePercentError);
  }

  return isAddServiceProvider;
}

export const serviceProviderInput: CreateServiceProviderInput = {
  serviceProviderId: '',
  name: '',
  serviceId: '',
  clientFees: {
    distribution: {
      distribution: 50,
    },
    fees: {
      flatFee: {
        currency: Currency.KGS,
        amount: 0,
      },
    },
  },
  serviceRewardFees: {
    distribution: {
      distribution: 50,
    },
    fees: {
      flatFee: {
        currency: Currency.KGS,
        amount: 0,
      },
    },
  },
  requiredFieldsMapping: [],
};

export const dynamicFeeStepsAmount = [
  {
    from: {
      amount: 0,
      currency: Currency.KGS,
    },
    fee: {
      flatFee: {
        amount: null,
        currency: Currency.KGS,
      },
    },
    dynamicFeeType: DynamicFeeType.AMOUNT,
  },
];

export const flatFeeAmount = { amount: null };
export const flatFeePercent = { flatFeePercent: null };

export function addInputsForFees(action: ServiceProviderValueFields) {
  let newFees;

  switch (action.feeType) {
    case FeesTypes.DYNAMIC_FEE_STEPS:
      return (newFees = { dynamicFeeSteps: dynamicFeeStepsAmount });

    case FeesTypes.FLAT_FEE:
      return (newFees = { ...flatFeeAmount });

    case FeesTypes.FLAT_FEE_PERCENT:
      return (newFees = flatFeePercent);

    default:
      return newFees;
  }
}

export function getFieldsForNameWithLocale(language, name) {
  let serviceFields;

  switch (language) {
    case Locale.RU:
      serviceFields = { name_ru: name };
      break;
    case Locale.KY:
      serviceFields = { name_ky: name };
      break;
    case Locale.EN:
      serviceFields = { name_en: name };
      break;
    default:
      serviceFields = { name_ru: name };
  }

  return serviceFields;
}

export function getValidationForLatin(name_en: string) {
  return name_en.replace(/[^a-zA-Z0-9=\-_$&()@]+/gi, ' ');
}

export function getValidationForCyrillic(name: string) {
  return name.replace(/[^А-я0-9=\-_$&()@]+/gi, ' ');
}

export const REGEX_LATIN = /[a-zA-Z]/;
export const REGEX_CYRILLIC = /[А-я]/;

export function cleanService(service) {
  if (!service.serviceProviders) {
    return service;
  }

  return {
    ...service,
    serviceProviders: service.serviceProviders.map((item) => {
      const { serviceProviderId, serviceId, clientFees, serviceRewardFees, requiredFieldsMapping } = item;

      return {
        serviceProviderId,
        serviceId,
        clientFees,
        serviceRewardFees,
        requiredFieldsMapping,
      };
    }),
  };
}

export function getFeesWithDistribution(fee: DistributionFees) {
  if (typeof fee.distribution === 'number' || typeof fee.distribution === 'string') {
    return {
      ...fee,
      distribution: {
        distribution: +fee.distribution,
      },
    };
  }

  if (fee.fees?.dynamicFeeSteps) {
    const { dynamicFeeSteps } = fee.fees;

    const distribution = dynamicFeeSteps.map((step) => {
      const distributionStep = {
        from: step.from,
        distribution: step.distribution,
      };

      delete step.distribution;

      return distributionStep;
    });

    return {
      ...fee,
      distribution: {
        dynamicDistributionSteps: distribution,
      },
    };
  }

  return fee;
}

export function mapServiceFees(fee) {
  if (typeof fee.distribution?.distribution === 'number') {
    return {
      ...fee,
      distribution: fee.distribution?.distribution,
    };
  }

  if (fee.distribution?.dynamicDistributionSteps) {
    const feeWithDistribution = fee.fees.dynamicFeeSteps.map((step, index) => {
      return {
        ...step,
        distribution: fee.distribution.dynamicDistributionSteps[index].distribution,
      };
    });

    delete fee.distribution.dynamicDistributionSteps;

    fee.distribution = null;

    return {
      ...fee,
      fees: {
        dynamicFeeSteps: feeWithDistribution,
      },
    };
  }
}

export function getServiceProviderName(id: string, serviceProviders: any, language: string) {
  const serviceProvider = serviceProviders.find((item) => item.id === id);

  const name = returnNameService(language, serviceProvider);

  return name;
}
