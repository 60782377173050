/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { serviceProviderInput } from '../../utils/helper';
import { UpdateService } from '../../utils/types';
import { UpdateServiceAction, UpdateServiceActionTypes } from './action';

export interface UpdateServiceState {
  error?: Error;
  isSuccess?: boolean;
  loading?: boolean;
  service?: UpdateService;
}

export const initialUpdateServiceState: UpdateServiceState = {
  isSuccess: false,
  service: {
    name_en: '',
    name_ru: '',
    name_ky: '',
    serviceProviders: [serviceProviderInput],
  },
};

export default function updateServiceReducer(state = initialUpdateServiceState, action: UpdateServiceAction) {
  switch (action.type) {
    case UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case UpdateServiceActionTypes.UPDATE_SERVICE_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        service: { $set: action.service },
      });
    }

    case UpdateServiceActionTypes.UPDATE_SERVICE_ERROR: {
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    }

    case UpdateServiceActionTypes.RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case UpdateServiceActionTypes.RESET_UPDATE_SERVICE_STATE:
      return update(state, { $set: initialUpdateServiceState });

    default:
      return state;
  }
}
