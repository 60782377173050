/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UpdateServiceProvider } from '../../../utils/types';
import { validateServiceProvider } from '../../utils/validate';
import { UpdateServiceProviderActionTypes, UpdateServiceProviderAction } from './actions';

export interface UpdateServiceProviderState {
  serviceProvider: UpdateServiceProvider;
  nameEnError?: Error;
  nameKyError?: Error;
  nameRuError?: Error;
  providerIdError?: Error;
  idError?: Error;
  isSuccess?: boolean;
  loading?: boolean;
  error?: Error;
  shouldShowConfirmationPopup?: boolean;
  backPath?: string;
}

export const initialUpdateServiceProviderState: UpdateServiceProviderState = {
  serviceProvider: {
    id: '',
    providerId: '',
    name_en: '',
    name_ky: '',
    name_ru: '',
  },
  isSuccess: false,
  shouldShowConfirmationPopup: false,
};

export default function updateServiceProviderReducer(
  state = initialUpdateServiceProviderState,
  action: UpdateServiceProviderAction,
) {
  switch (action.type) {
    case UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        serviceProvider: { $set: action.serviceProvider },
      });

    case UpdateServiceProviderActionTypes.GET_SERVICE_PROVIDER_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
        serviceProvider: { $set: action.serviceProvider },
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateServiceProviderActionTypes.UPDATE_SERVICE_PROVIDER_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateServiceProviderActionTypes.RESET_ERROR_UPDATE_SERVICE_PROVIDER:
      return update(state, {
        $unset: ['error'],
      });

    case UpdateServiceProviderActionTypes.RESET_UPDATE_SERVICE_PROVIDER_STATE:
      return update(state, { $set: initialUpdateServiceProviderState });

    case UpdateServiceProviderActionTypes.ADD_UPDATE_SERVICE_PROVIDER_FIELDS:
      return validateServiceProvider(state, action.fields);

    case UpdateServiceProviderActionTypes.ADD_BACK_PATH_UPDATE_SERVICE_PROVIDER:
      return update(state, {
        backPath: { $set: action.backPath },
      });

    default:
      return state;
  }
}
