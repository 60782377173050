/* External dependencies */
import gql from 'graphql-tag';

export const updateServiceMutation = gql`
  mutation updateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
    }
  }
`;
