/* Local dependencies */
import { UIException } from '../../../redux/exceptions';
import { RequiredFieldsMapping } from '../mapping/types';
import { ServiceProviderStatus } from '../serviceProviders/utils/types';

export interface GetService {
  id: string;
}

export const MIN_NUMBER_FOR_COMMISSION = -1;

export interface DeleteServiceInput {
  id: string;
}

interface Logo {
  url: string;
}

export interface ProcessService {
  id?: string;
  exactAmountRequired?: boolean;
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  serviceProviders: CreateServiceProviderInput[] | ServiceProvidersForService;
  status?: ServiceStatus;
  maxAmount?: Money;
  minAmount?: Money;
}

export interface CreateServiceProviderInput {
  serviceProviderId: string;
  name: string;
  serviceId: string;
  clientFees: DistributionFees;
  serviceRewardFees: DistributionFees;
  requiredFieldsMapping: RequiredFieldsMapping;
}

export interface Distribution {
  distribution?: number;
  flatDistribution?: Money;
  dynamicDistributionSteps?: DynamicDistributionSteps;
}

export interface DistributionFees {
  distribution: Distribution;
  fees: ServiceFees;
}

export type DynamicDistributionSteps = DynamicDistributionStep[];

export interface DynamicDistributionStep {
  distribution: Distribution;
  from: Money;
}

export interface UpdateService {
  id: string;
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  serviceProviders?: UpdateServiceProviderFieldsForService[];
  status?: ServiceStatus;
  statusMessage?: string;
}

export type UpdateServiceField =
  | string
  | boolean
  | Money
  | Logo
  | ServiceStatus
  | UpdateServiceProviderFieldsForService[];

export interface UpdateServiceFields {
  id?: string;
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  serviceProviders?: UpdateServiceProviderFieldsForService[];
  status?: ServiceStatus;
  statusMessage?: string;
}

export enum ServiceStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  INACTIVE = 'INACTIVE',
}

export enum ServicesStatusMessage {
  OTHER = 'OTHER',
  SUPPLIER_FAILURE = 'SUPPLIER_FAILURE',
  TECHNICAL_WORKS = 'TECHNICAL_WORKS',
}

export type ServiceStasuses = [ServiceStatus];

export type ServiceFeeStep = {
  dynamicFeeType?: DynamicFeeType;
  fee: ServiceFees;
  from: Money;
};

export type ServiceFees = {
  dynamicFeeSteps?: ServiceFeeStep[];
  flatFee?: Money;
  flatFeePercent?: number;
  distribution: Distribution;
};

export interface ServiceFeesWithValidation extends ServiceFees {
  validationPassed: boolean;
}

export type ServiceProviderFeeStepUpdateFields = {
  dynamicFeeType?: DynamicFeeType;
  fee?: ServiceFees;
  from?: Money;
  to?: Money;
  toAmountError?: Error | null | UIException;
  fromAmountError?: Error | null | UIException;
  flatFeeAmountError?: Error | null | UIException;
  flatFeePercentError?: Error | null | UIException;
};

export type ServiceFeeDynamicStepUpdateFieldsAction = {
  stepIndex?: number;
  stepValue: ServiceProviderFeeStepUpdateFields[];
};

export type ServiceFeesUpdateFieldsAction = {
  index?: number;
  value?: ServiceProviderValueFields;
};

export type ServiceProviderValueFields = {
  amountError?: Error;
  currency?: Currency;
  currencyError?: Error;
  dynamicFeePercentError?: Error;
  dynamicFeeSteps?: ServiceFeeDynamicStepUpdateFieldsAction | ServiceProviderFeeStepUpdateFields[];
  dynamicFlatFeePercentError?: Error;
  feeType?: string;
  flatFee?: Money;
  flatFeePercent?: number;
  flatFeePercentError?: Error;
};

export enum Currency {
  KGS = 'KGS',
  RUB = 'RUB',
  USD = 'USD',
}

export const SERVICES = {
  AVERSPAY: {
    id: 'averspay',
  },
};

export type Money = {
  amount?: number;
  currency?: Currency;
};

export interface Service {
  id: string;
  lastDisabledDate?: number;
  lastEnabledDate?: number;
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  registrationDate: number;
  serviceProviders: ServiceProvidersForService;
  status: ServiceStatus;
  statusMessage?: string;
  __typename: ServiceTypename;
}

export type ServiceProvidersForService = ServiceProviderForService[];

export interface ServiceProviderForService {
  clientFees: DistributionFees;
  serviceRewardFees: DistributionFees;
  serviceProviderId: string;
  serviceId: string;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
}

export type UpdateServiceProviderFieldsForService = {
  id?: string;
  name?: string;
  serviceId?: string;
  requiredFieldsMapping: RequiredFieldsMapping;
};

export interface UpdateService {
  status?: ServiceStatus;
  statusMessage?: string;
}

// SERVICE PROVIDERS

export interface ServiceProvider {
  id: string;
  providerId: string;
  name_en: string;
  name_ky: string;
  name_ru: string;
  status: ServiceProviderStatus;
}

export interface UpdateServiceProviderFields {
  id?: string;
  providerId?: string;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  status?: ServiceProviderStatus;
}

export interface UpdateServiceProvider {
  id: string;
  providerId?: string;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  status?: ServiceProviderStatus;
}

export interface CreateServiceProvider {
  id?: string;
  providerId?: string;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
}

export interface ListServiceProvidersInput {
  from?: number;
  query?: string;
  size?: number;
}

export interface ListServiceProviders {
  total: number;
  serviceProviders: [ServiceProvider];
}

export enum FeesTypes {
  DYNAMIC_FEE_STEPS = 'dynamicFeeSteps',
  FLAT_FEE = 'flatFee',
  FLAT_FEE_PERCENT = 'flatFeePercent',
}

export enum DistributionTypes {
  DISTRIBUTION = 'distribution',
  DYNAMIC_DISTRIBUTION_STEPS = 'dynamicDistributionSteps',
  FLAT_DISTRIBUTION = 'flatDistribution',
}

export enum DynamicFeeType {
  AMOUNT = 'amount',
  PERCENT = 'percent',
}

export enum ServiceTypename {
  SERVICE = 'Service',
}

export enum ServiceStatusType {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  INACTIVE = 'INACTIVE',
}

export enum ServiceType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface GetServiceProviderServiceInput {
  serviceProviderId: string;
  serviceId: string;
}

export type ServiceProviderServiceRequiredField = {
  name: string;
  description: string;
};

export interface ServiceProviderService {
  serviceId: string;
  requiredFields: ServiceProviderServiceRequiredField[];
}

export enum Provider {
  DEMIR_BANK = 'DEMIR_BANK',
  PAY24 = 'PAY24',
  QUICKPAY = 'QUICKPAY',
}
