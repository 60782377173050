/* External dependencies */
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/demirbank';
import { listUsersQuery } from './queries';
import {
  ListUsersAction,
  ListUsersActionTypes,
  listUsersFailedAction,
  ListUsers,
  listUsersSucceeded,
  ListUsersSuccess,
} from './actions';

export default function listUsersEpic(action$, state$): Observable<ListUsersAction> {
  return action$.pipe(
    filter((action: ListUsersAction) => action.type === ListUsersActionTypes.LIST_USERS_REQUEST),
    switchMap((action: ListUsers) =>
      listUsers(action, state$.value.users.size).catch((error) => listUsersFailedAction(error)),
    ),
  );
}

export async function listUsers({ searchString, currentPage }: ListUsers, size: number): Promise<ListUsersSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listUsers: { total, users },
    },
  } = await graphQLClient.query({
    query: listUsersQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listUsersSucceeded(total, users);
}
