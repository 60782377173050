// Local dependencies
import { Service } from '../../utils/types';
import { GetServiceAction, GetServiceActionTypes } from './actions';

export interface GetServiceState {
  loading: boolean;
  isSuccess: boolean;
  service: Service;
  error: Error;
}

export const getServiceInitialState: GetServiceState = {
  loading: false,
  isSuccess: false,
  service: null,
  error: null,
};

export default function getServiceReducer(state = getServiceInitialState, action: GetServiceAction) {
  switch (action.type) {
    case GetServiceActionTypes.GET_SERVICE_REQUEST: {
      return { ...state, loading: true };
    }

    case GetServiceActionTypes.GET_SERVICE_SUCCESS: {
      return { ...state, loading: false, isSuccess: true, service: action.service };
    }

    case GetServiceActionTypes.GET_SERVICE_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    case GetServiceActionTypes.RESET_GET_SERVICE_STATE: {
      return getServiceInitialState;
    }

    case GetServiceActionTypes.RESET_GET_SERVICE_ERROR: {
      return { ...state, error: null };
    }

    default: {
      return state;
    }
  }
}
