/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../../clients/demirbank';
import {
  DeleteServiceProviderAction,
  DeleteServiceProviderActionTypes,
  deleteServiceProviderFailed,
  deleteServiceProviderSucceeded,
  DeleteServiceProviderSuccess,
} from './actions';
import { deleteServiceProviderMutation } from './mutation';

export default function deleteServiceProviderEpic(action$) {
  return action$.pipe(
    filter(
      (action: DeleteServiceProviderAction) =>
        action.type === DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_REQUEST,
    ),
    switchMap((action: DeleteServiceProviderAction) =>
      deleteServiceProvider(action).catch((error) => deleteServiceProviderFailed(error)),
    ),
  );
}

export async function deleteServiceProvider(action): Promise<DeleteServiceProviderSuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: {
      deleteService: { statusMessage },
    },
  } = await graphQLClient.mutate({
    mutation: deleteServiceProviderMutation,
    variables: {
      input: { id },
    },
  });

  return deleteServiceProviderSucceeded(statusMessage);
}
