/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UIException } from '../../../../../redux/exceptions';
import { DeleteServiceProviderAction, DeleteServiceProviderActionTypes } from './actions';

export interface DeleteServiceProviderState {
  error?: UIException;
  isConfirmationPopupDeleteServiceProvider?: boolean;
  isSuccess: boolean;
  loading: boolean;
  statusMessage?: string;
}

const DeleteServiceProviderInitialState: DeleteServiceProviderState = {
  isConfirmationPopupDeleteServiceProvider: false,
  isSuccess: false,
  loading: false,
};

export default function deleteServiceProviderReducer(
  state = DeleteServiceProviderInitialState,
  action: DeleteServiceProviderAction,
) {
  switch (action.type) {
    case DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_SUCCESS:
      return update(state, {
        isConfirmationPopupDeleteServiceProvider: { $set: false },
        isSuccess: { $set: true },
        loading: { $set: false },
        statusMessage: { $set: action.statusMessage },
      });

    case DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_ERROR:
      return update(state, {
        isConfirmationPopupDeleteServiceProvider: { $set: false },
        loading: { $set: false },
        error: { $set: action.error },
      });

    case DeleteServiceProviderActionTypes.DELETE_SERVICE_PROVIDER_RESET:
      return update(state, { $set: DeleteServiceProviderInitialState });

    case DeleteServiceProviderActionTypes.SHOW_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP:
      return update(state, {
        isConfirmationPopupDeleteServiceProvider: { $set: true },
      });

    case DeleteServiceProviderActionTypes.HIDE_CONFIRMATION_DELETE_SERVICE_PROVIDER_POPUP:
      return update(state, {
        isConfirmationPopupDeleteServiceProvider: { $set: false },
      });

    default:
      return state;
  }
}
