/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/demirbank';
import { cleanPayload, cleanUndefinedValuesFromPayload, removeSpaces } from '../../../common/helpers';
import { cleanService } from '../../utils/helper';
import { Service } from '../../utils/types';
import {
  UpdateServiceAction,
  UpdateServiceActionTypes,
  UpdateServiceRequest,
  updateServiceFailed,
  updateServiceSucceeded,
} from './action';
import { updateServiceMutation } from './mutation';

export function updateServiceEpic(action$) {
  return action$.pipe(
    filter((action: UpdateServiceAction) => action.type === UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST),
    switchMap((action: UpdateServiceRequest) =>
      updateService(action)
        .then(updateServiceSucceeded)
        .catch((error: Error) => updateServiceFailed(error)),
    ),
  );
}

export async function updateService(action): Promise<Service> {
  const graphQLClient = await getClient();
  const { service } = action;

  const serviceInput = cleanUndefinedValuesFromPayload(service);
  const input = cleanService(removeSpaces(cleanPayload(serviceInput)));

  if (input?.logo?.url.startsWith('https')) {
    delete input.logo;
  }

  await graphQLClient.mutate({
    mutation: updateServiceMutation,
    variables: { input },
  });

  return input as Service;
}
