/* External dependencies */
import gql from 'graphql-tag';

export const blockServiceMutation = gql`
  mutation blockService($input: BlockServiceInput!) {
    blockService(input: $input) {
      id
    }
  }
`;
