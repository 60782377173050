/* External dependencies */
import gql from 'graphql-tag';

export const deleteServiceProviderMutation = gql`
  mutation deleteServiceProvider($input: DeleteServiceProviderInput!) {
    deleteServiceProvider(input: $input) {
      statusMessage
    }
  }
`;
