/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UIException } from '../../../../../redux/exceptions';
import { ServiceProvider } from '../../../utils/types';
import { ListServiceProvidersAction, ListServiceProvidersActionTypes, ListServiceProvidersFitler } from './actions';

export interface ServiceProvidersState {
  error?: UIException;
  loading?: boolean;
  currentPage?: number;
  from?: number;
  filter?: ListServiceProvidersFitler;
  searchString?: string;
  serviceProviders: ServiceProvider[];
  selectedServiceProviders: ServiceProvider[];
  size?: number;
  total: number;
}

const size: number = 20;

export const initialServiceProvidersState: ServiceProvidersState = {
  serviceProviders: [],
  selectedServiceProviders: [],
  currentPage: 1,
  loading: false,
  searchString: '',
  size,
  total: 0,
};

export default function serviceProvidersReducer(
  state = initialServiceProvidersState,
  action: ListServiceProvidersAction,
) {
  switch (action.type) {
    case ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_REQUEST:
      const { currentPage, searchString, size, filter } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        searchString: { $set: searchString },
        currentPage: { $set: currentPage },
        size: { $set: size },
      });

    case ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        serviceProviders: { $set: action.serviceProviders },
        total: { $set: action.total },
      });

    case ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListServiceProvidersActionTypes.SELECT_SERVICE_PROVIDER_LIST:
      return update(state, {
        selectedServiceProviders: { $set: [action.serviceProvider] },
      });

    case ListServiceProvidersActionTypes.RESET_SELECTED_SERVICE_PROVIDER:
      return update(state, {
        selectedServiceProviders: { $set: initialServiceProvidersState.selectedServiceProviders },
      });

    case ListServiceProvidersActionTypes.RESET_LIST_SERVICE_PROVIDERS_STATE:
      return update(state, { $set: initialServiceProvidersState });

    case ListServiceProvidersActionTypes.RESET_LIST_SERVICE_PROVIDERS_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
