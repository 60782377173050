/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { CreateServiceProvider } from '../../../utils/types';
import { CreateServiceProviderActionTypes, CreateServiceProviderAction } from './actions';
import { validateServiceProvider } from '../../utils/validate';

export interface CreateServiceProviderState {
  serviceProvider: CreateServiceProvider;
  nameEnError?: Error;
  nameKyError?: Error;
  nameRuError?: Error;
  idError?: Error;
  providerIdError?: Error;
  isSuccess?: boolean;
  loading?: boolean;
  error?: Error;
  shouldShowConfirmationPopup?: boolean;
  backPath?: string;
}

export const initialCreateServiceProviderState: CreateServiceProviderState = {
  serviceProvider: {
    id: '',
    providerId: '',
    name_en: '',
    name_ky: '',
    name_ru: '',
  },
  isSuccess: false,
  shouldShowConfirmationPopup: false,
};

export default function createServiceProviderReducer(
  state = initialCreateServiceProviderState,
  action: CreateServiceProviderAction,
) {
  switch (action.type) {
    case CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
        serviceProvider: { $set: action.serviceProvider },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateServiceProviderActionTypes.RESET_ERROR_CREATE_SERVICE_PROVIDER:
      return update(state, {
        $unset: ['error'],
      });

    case CreateServiceProviderActionTypes.RESET_CREATE_SERVICE_PROVIDER_STATE:
      return update(state, { $set: initialCreateServiceProviderState });

    case CreateServiceProviderActionTypes.ADD_CREATE_SERVICE_PROVIDER_FIELDS:
      return validateServiceProvider(state, action.fields);

    case CreateServiceProviderActionTypes.ADD_BACK_PATH_CREATE_SERVICE_PROVIDER:
      return update(state, {
        backPath: { $set: action.backPath },
      });

    default:
      return state;
  }
}
