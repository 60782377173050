// External dependencies
import { gql } from '@apollo/client/core';

export const getClientQuery = gql`
  query getClient($input: GetClientInput!) {
    getClient(input: $input) {
      id
      name
      callbackUrls
      uiCustomization {
        imageUrl
      }
    }
  }
`;
