/* Local dependencies */
import { UpdateService } from '../../utils/types';

export enum UpdateServiceActionTypes {
  UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST',
  UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS',
  UPDATE_SERVICE_ERROR = 'UPDATE_SERVICE_ERROR',
  RESET_UPDATE_SERVICE_STATE = 'RESET_UPDATE_SERVICE_STATE',
  RESET_ERROR = 'RESET_ERROR',
}

export interface UpdateServiceRequest {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST;
  service: UpdateService;
}

export interface UpdateServiceSuccess {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_SUCCESS;
  service: UpdateService;
}

export interface UpdateServiceError {
  type: UpdateServiceActionTypes.UPDATE_SERVICE_ERROR;
  error: Error;
}

export interface ResetUpdateServiceState {
  type: UpdateServiceActionTypes.RESET_UPDATE_SERVICE_STATE;
}

export interface ResetError {
  type: UpdateServiceActionTypes.RESET_ERROR;
}

export type UpdateServiceAction =
  | UpdateServiceRequest
  | UpdateServiceSuccess
  | UpdateServiceError
  | ResetUpdateServiceState
  | ResetError;

export function updateServiceRequest(service: UpdateService): UpdateServiceRequest {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST,
    service,
  };
}

export function updateServiceSucceeded(service: UpdateService): UpdateServiceSuccess {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_SUCCESS,
    service,
  };
}

export function updateServiceFailed(error: Error): UpdateServiceError {
  return {
    type: UpdateServiceActionTypes.UPDATE_SERVICE_ERROR,
    error,
  };
}

export function resetUpdateServiceState(): ResetUpdateServiceState {
  return {
    type: UpdateServiceActionTypes.RESET_UPDATE_SERVICE_STATE,
  };
}

export function resetError(): ResetError {
  return {
    type: UpdateServiceActionTypes.RESET_ERROR,
  };
}
