/* Local dependencies */
import { UIException } from '../../../../redux/exceptions';

export class FirstNameRequiredException extends UIException {
  code = 'FirstNameRequiredException';
}

export class LastNameRequiredException extends UIException {
  code = 'LastNameRequiredException';
}

export class RoleRequiredException extends UIException {
  code = 'RoleRequiredException';
}

export class StatusRequiredException extends UIException {
  code = 'UserStatusRequiredException';
}

export class EmailRequiredException extends UIException {
  code = 'EmailRequiredException';
}

export class EmailInvalidException extends UIException {
  code = 'EmailInvalidException';
}

export class DuplicateUserException extends UIException {
  code = 'DuplicateUserException';
}
