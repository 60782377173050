/* External dependencies */
import gql from 'graphql-tag';

export const createServiceMutation = gql`
  mutation createService($input: CreateServiceInput!) {
    createService(input: $input) {
      logo {
        url
      }
      name_en
      name_ru
      name_ky
      status
      id
      serviceProviders {
        serviceProviderId
      }
    }
  }
`;
