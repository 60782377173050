/* External dependencies */
import gql from 'graphql-tag';

export const getServiceProviderQuery = gql`
  query getServiceProvider($input: GetServiceProviderInput!) {
    getServiceProvider(input: $input) {
      id
      providerId
      name_en
      name_ky
      name_ru
    }
  }
`;
