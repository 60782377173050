/* Local dependencies */

export enum DeleteServiceActionTypes {
  DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR',
  DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS',
  DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST',
  DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS',
  DELETE_SERVICE_ERROR = 'DELETE_SERVICE_ERROR',
  DELETE_SERVICE_RESET = 'DELETE_SERVICE_RESET',
  HIDE_CONFIRMATION_DELETE_SERVICE_POPUP = 'HIDE_CONFIRMATION_DELETE_SERVICE_POPUP',
  SHOW_CONFIRMATION_DELETE_SERVICE_POPUP = 'SHOW_CONFIRMATION_DELETE_SERVICE_POPUP',
}

export type DeleteServiceAction =
  | DeleteCategory
  | DeleteCategorySuccess
  | DeleteCategoryError
  | DeleteServiceRequest
  | DeleteServiceSuccess
  | DeleteServiceError
  | DeleteServiceReset
  | HideConfirmationDeleteServicePopup
  | ShowConfirmationDeleteServicePopup;

export interface DeleteCategory {
  id: string;
  type: DeleteServiceActionTypes.DELETE_CATEGORY_REQUEST;
}

export interface DeleteCategorySuccess {
  statusMessage: string;
  type: DeleteServiceActionTypes.DELETE_CATEGORY_SUCCESS;
}

export interface DeleteCategoryError {
  error: Error;
  type: DeleteServiceActionTypes.DELETE_CATEGORY_ERROR;
}

export interface DeleteServiceRequest {
  type: DeleteServiceActionTypes.DELETE_SERVICE_REQUEST;
  id: string;
}

export interface DeleteServiceSuccess {
  type: DeleteServiceActionTypes.DELETE_SERVICE_SUCCESS;
  statusMessage: string;
}

export interface DeleteServiceError {
  type: DeleteServiceActionTypes.DELETE_SERVICE_ERROR;
  error: Error;
}

export interface DeleteServiceReset {
  type: DeleteServiceActionTypes.DELETE_SERVICE_RESET;
}

export interface ShowConfirmationDeleteServicePopup {
  type: DeleteServiceActionTypes.SHOW_CONFIRMATION_DELETE_SERVICE_POPUP;
}

export interface HideConfirmationDeleteServicePopup {
  type: DeleteServiceActionTypes.HIDE_CONFIRMATION_DELETE_SERVICE_POPUP;
}

export function deleteCategoryRequest(id: string): DeleteCategory {
  return {
    id,
    type: DeleteServiceActionTypes.DELETE_CATEGORY_REQUEST,
  };
}

export function deleteCategorySucceeded(statusMessage: string): DeleteCategorySuccess {
  return {
    statusMessage,
    type: DeleteServiceActionTypes.DELETE_CATEGORY_SUCCESS,
  };
}

export function deleteCategoryFailed(error: Error): DeleteCategoryError {
  return {
    error,
    type: DeleteServiceActionTypes.DELETE_CATEGORY_ERROR,
  };
}

export function deleteServiceRequest(id: string): DeleteServiceRequest {
  return {
    type: DeleteServiceActionTypes.DELETE_SERVICE_REQUEST,
    id,
  };
}

export function deleteServiceSucceeded(statusMessage: string): DeleteServiceSuccess {
  return {
    type: DeleteServiceActionTypes.DELETE_SERVICE_SUCCESS,
    statusMessage,
  };
}

export function deleteServiceFailed(error: Error): DeleteServiceError {
  return {
    type: DeleteServiceActionTypes.DELETE_SERVICE_ERROR,
    error,
  };
}

export function deleteServiceReset(): DeleteServiceReset {
  return {
    type: DeleteServiceActionTypes.DELETE_SERVICE_RESET,
  };
}

export function showConfirmationDeleteServicePopup(): ShowConfirmationDeleteServicePopup {
  return {
    type: DeleteServiceActionTypes.SHOW_CONFIRMATION_DELETE_SERVICE_POPUP,
  };
}

export function hideConfirmationDeleteServicePopup(): HideConfirmationDeleteServicePopup {
  return {
    type: DeleteServiceActionTypes.HIDE_CONFIRMATION_DELETE_SERVICE_POPUP,
  };
}
