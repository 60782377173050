/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UpdateUserInput } from '../../createUser/redux/actions';
import { validateForm } from '../../createUser/redux/validation';
import { UserActions, UserActionTypes } from './actions';

export interface UserState {
  error?: Error;
  firstNameError?: Error;
  lastNameError?: Error;
  loading?: boolean;
  emailError?: Error;
  roleError?: Error;
  isFormChanged?: boolean;
  isUserUpdated?: boolean;
  user: UpdateUserInput;
}

export const initialUserState: UserState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    status: '',
  },
};

export default function userReducer(state = initialUserState, action: UserActions) {
  switch (action.type) {
    case UserActionTypes.RESET_USER_DETAILS:
      return update(state, {
        $set: initialUserState,
      });

    case UserActionTypes.GET_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UserActionTypes.GET_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        user: { $set: action.user },
      });

    case UserActionTypes.GET_USER_FAILURE:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case UserActionTypes.UPDATE_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isFormChanged: { $set: false },
        isUserUpdated: { $set: false },
      });

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return update(state, {
        isFormChanged: { $set: false },
        loading: { $set: false },
        user: { $set: action.user },
        isUserUpdated: { $set: true },
      });

    case UserActionTypes.UPDATE_USER_FAILURE:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        isUserUpdated: { $set: false },
      });

    case UserActionTypes.UPDATE_USER_FIELDS:
      return validateForm(state, action.updates);

    case UserActionTypes.RESET_USER_DETAILS_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
