/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ListTransactionsAction, ListTransactionsActionTypes, ListTransactionsFilter } from './actions';
import { Transaction } from './types';

export interface TransactionsState {
  currentPage: number;
  endDate?: Date;
  error?: Error;
  filter?: ListTransactionsFilter;
  loading?: boolean;
  locale?: string;
  payments: Transaction[];
  searchString: string;
  selectedTransactions?: [];
  size: number;
  startDate?: Date;
  total: number;
}

const size: number = 20;
let lastSevenDate = new Date();
lastSevenDate.setDate(lastSevenDate.getDate() - 7);

const currentTime = new Date();

export const initialTransactionsState: TransactionsState = {
  currentPage: 1,
  endDate: currentTime,
  filter: {},
  loading: true,
  locale: 'RU',
  payments: [],
  searchString: '',
  startDate: lastSevenDate,
  selectedTransactions: [],
  size,
  total: 0,
};

export default function transactionsReducer(state = initialTransactionsState, action: ListTransactionsAction) {
  switch (action.type) {
    case ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST:
      const { currentPage, filter, searchString, endDate, startDate } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        startDate: { $set: startDate ?? lastSevenDate },
        endDate: { $set: endDate ?? currentTime },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        payments: { $set: action.payments },
        total: { $set: action.total },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
