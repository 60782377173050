/* Local dependencies */
import { Provider } from '../../services/utils/types';
import { Transaction, TransactionStatus } from './types';

export enum ListTransactionsActionTypes {
  LIST_TRANSACTIONS_REQUEST = 'LIST_TRANSACTIONS_REQUEST',
  LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS',
  LIST_TRANSACTIONS_ERROR = 'LIST_TRANSACTIONS_ERROR',
  SORT_TRANSACTIONS_BY = 'SORT_TRANSACTIONS_BY',
  SET_TRANSACTIONS_START_DATE = 'SET_TRANSACTIONS_START_DATE',
  SET_TRANSACTIONS_END_DATE = 'SET_TRANSACTIONS_END_DATE',
  DEBIT_PAYMENT_TYPE = 'DEBIT_PAYMENT_TYPE',
}

export interface ListTransactions {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST;
  startDate?: Date;
  endDate?: Date;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
}

export interface ListTransactionsSuccess {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS;
  payments: Transaction[];
  total: number;
}

export interface ListTransactionsError {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR;
  error: Error;
}

export interface ListTransactionsFilter {
  accountId?: string;
  statuses?: TransactionStatus[];
  provider?: Provider;
  userId?: string;
}

export interface ListPaymentsSort {
  type: ListTransactionsActionTypes.SORT_TRANSACTIONS_BY;
  field: string;
}

export interface TransactionsStartDate {
  type: ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE;
  startDate: Date;
}

export interface TransactionsEndDate {
  type: ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE;
  endDate: Date;
}

export type ListTransactionsAction =
  | ListTransactions
  | ListTransactionsSuccess
  | ListTransactionsError
  | TransactionsStartDate
  | TransactionsEndDate;

export interface ListTransactionsInput {
  currentPage?: number;
  endDate?: Date;
  filter?: ListTransactionsFilter;
  searchString?: string;
  startDate?: Date;
}

export function listTransactions({
  searchString,
  filter,
  currentPage,
  startDate,
  endDate,
}: ListTransactionsInput): ListTransactions {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST,
    searchString,
    currentPage,
    startDate,
    filter,
    endDate,
  };
}

export function listTransactionsSucceeded(total: number, payments: Transaction[]): ListTransactionsSuccess {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS,
    payments,
    total,
  };
}

export function listTransactionsFailed(error: Error): ListTransactionsError {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR,
    error,
  };
}

export function sortTransactionsBy(field: string) {
  return {
    type: ListTransactionsActionTypes.SORT_TRANSACTIONS_BY,
    field,
  };
}

export function setStartDate(startDate: Date): TransactionsStartDate {
  return {
    type: ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE,
    startDate,
  };
}

export function setEndDate(endDate: Date): TransactionsEndDate {
  return {
    type: ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE,
    endDate,
  };
}
