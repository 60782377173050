/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ActiveServiceAction, ActiveServiceActionTypes } from './actions';

export interface ActiveServiceState {
  error?: Error;
  isActiveServiceSuccess?: boolean;
  loading?: boolean;
}

export const initialActiveServiceState: ActiveServiceState = {
  isActiveServiceSuccess: false,
};

export default function activeServiceReducer(
  state = initialActiveServiceState,
  action: ActiveServiceAction,
) {
  switch (action.type) {
    case ActiveServiceActionTypes.ACTIVE_SERVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case ActiveServiceActionTypes.ACTIVE_SERVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isActiveServiceSuccess: { $set: true },
      });

    case ActiveServiceActionTypes.ACTIVE_SERVICE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ActiveServiceActionTypes.RESET_ACTIVE_SERVICE_STATE:
      return update(state, { $set: initialActiveServiceState });

    default:
      return state;
  }
}
