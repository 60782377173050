export enum BlockServiceActionTypes {
  BLOCK_SERVICE_REQUEST = 'BLOCK_SERVICE_REQUEST',
  BLOCK_SERVICE_SUCCESS = 'BLOCK_SERVICE_SUCCESS',
  BLOCK_SERVICE_ERROR = 'BLOCK_SERVICE_ERROR',
  RESET_BLOCK_SERVICE_STATE = 'RESET_BLOCK_SERVICE_STATE',
}

export interface BlockServiceRequest {
  type: BlockServiceActionTypes.BLOCK_SERVICE_REQUEST;
  id: string;
}

export interface BlockServiceSuccess {
  type: BlockServiceActionTypes.BLOCK_SERVICE_SUCCESS;
}

export interface BlockServiceError {
  type: BlockServiceActionTypes.BLOCK_SERVICE_ERROR;
  error: Error;
}

export interface ResetBlockServiceState {
  type: BlockServiceActionTypes.RESET_BLOCK_SERVICE_STATE;
}

export type BlockServiceAction =
  | BlockServiceRequest
  | BlockServiceSuccess
  | BlockServiceError
  | ResetBlockServiceState;

export function blockServiceRequest(id: string): BlockServiceRequest {
  return {
    type: BlockServiceActionTypes.BLOCK_SERVICE_REQUEST,
    id,
  };
}

export function blockServiceSuccessed(): BlockServiceSuccess {
  return {
    type: BlockServiceActionTypes.BLOCK_SERVICE_SUCCESS,
  };
}

export function blockServiceError(error: Error): BlockServiceError {
  return {
    type: BlockServiceActionTypes.BLOCK_SERVICE_ERROR,
    error,
  };
}

export function resetBlockServiceState(): ResetBlockServiceState {
  return {
    type: BlockServiceActionTypes.RESET_BLOCK_SERVICE_STATE,
  };
}
