/* External dependencies */
import gql from 'graphql-tag';

export const activeServiceMutation = gql`
  mutation activeService($input: ActiveServiceInput!) {
    activeService(input: $input) {
      id
    }
  }
`;
