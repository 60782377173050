/* External dependencies */
import gql from 'graphql-tag';

export const updateServiceProviderMutation = gql`
  mutation updateServiceProvider($input: UpdateServiceProviderInput!) {
    updateServiceProvider(input: $input) {
      id
      providerId
      name_en
      name_ky
      name_ru
    }
  }
`;
