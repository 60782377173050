/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { serviceProviderInput } from '../../utils/helper';
import { CreateServiceProviderInput, ProcessService } from '../../utils/types';
import { ProcessServiceAction, ProcessServiceActionTypes, validateService } from './actions';
import validateServiceFields from './validations';

export interface ProcessServiceErrors {
  maxAmount: any;
  minAmount: any;
  id: Error | null;
  name_en?: Error | null;
  name_ky?: Error | null;
  name_ru?: Error | null;
  serviceProviders?: [
    {
      serviceProviderId: Error | null;
      serviceId: Error | null;
    },
  ];
}

export interface ProcessServiceState {
  loading?: boolean;
  service?: ProcessService;
  serviceErrors?: ProcessServiceErrors;
  validationPassed: boolean;
}

export const initialProcessServiceState: ProcessServiceState = {
  service: {
    id: '',
    exactAmountRequired: false,
    name_en: '',
    name_ru: '',
    name_ky: '',
    serviceProviders: [serviceProviderInput],
  },
  serviceErrors: {
    name_en: null,
    name_ky: null,
    name_ru: null,
    serviceProviders: [
      {
        serviceProviderId: null,
        serviceId: null,
      },
    ],
    id: null,
  },
  validationPassed: false,
};

export default function ProcessServiceReducer(state = initialProcessServiceState, action: ProcessServiceAction) {
  switch (action.type) {
    case ProcessServiceActionTypes.RESET_CREATE_SERVICE_STATE:
      return initialProcessServiceState;

    case ProcessServiceActionTypes.UPDATE_SERVICE_FIELDS:
      const [name, value] = action.updates;

      return update(state, {
        service: {
          $set: update(state.service, {
            [name]: { $set: value },
          }),
        },
        validationPassed: { $set: false },
        serviceErrors: {
          $set: update(state.serviceErrors, {
            [name]: { $set: null },
          }),
        },
      });

    case ProcessServiceActionTypes.CHANGE_SERVICE_PROVIDER_FIELDS:
      const { serviceProvider } = action;

      // remove properties with undefined values
      const cleanedServiceProvider = JSON.parse(JSON.stringify(serviceProvider));

      const newState = update(state, {
        service: {
          $set: update(state.service, {
            serviceProviders: {
              $set: update(state.service?.serviceProviders, {
                [action.index]: {
                  $set: { ...state.service?.serviceProviders[action.index], ...cleanedServiceProvider },
                },
              }),
            },
          }),
        },
        validationPassed: { $set: false },
      });

      if (cleanedServiceProvider.serviceId || cleanedServiceProvider.serviceProviderId) {
        return update(newState, {
          serviceErrors: {
            $set: update(state.serviceErrors, {
              serviceProviders: {
                $set: update(state.serviceErrors.serviceProviders, {
                  [action.index]: {
                    serviceId: { $set: cleanedServiceProvider.serviceId && null },
                    serviceProviderId: { $set: cleanedServiceProvider.id && null },
                  },
                }),
              },
            }),
          },
        });
      }

      return newState;

    case ProcessServiceActionTypes.ADD_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE:
      const serviceProvidersErrorsCount =
        state.service.serviceProviders.length - state.serviceErrors.serviceProviders?.length;

      const serviceProvidersErrors = Array(serviceProvidersErrorsCount + 1).fill({
        serviceProviderId: null,
        serviceId: null,
      });

      return update(state, {
        service: {
          $set: update(state.service, {
            serviceProviders: {
              $set: update(state.service?.serviceProviders, {
                $push: [serviceProviderInput],
              }),
            },
          }),
        },
        serviceErrors: {
          $set: update(state.serviceErrors, {
            serviceProviders: {
              $set: update(state?.serviceErrors?.serviceProviders, {
                $push: serviceProvidersErrors,
              }),
            },
          }),
        },
      });

    case ProcessServiceActionTypes.REMOVE_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE:
      return update(state, {
        service: {
          $set: update(state.service, {
            serviceProviders: {
              $set: update(state.service.serviceProviders, {
                $splice: [[action.index, 1]],
              }),
            },
          }),
        },
        serviceErrors: {
          $set: update(state.serviceErrors, {
            serviceProviders: {
              $set: update(state.service.serviceProviders, {
                $splice: [[action.index, 1]],
              }),
            },
          }),
        },
      });

    case ProcessServiceActionTypes.VALIDATE_SERVICE: {
      const { fully } = action;

      if (!fully) {
        return { ...validateServiceFields(state), validationPassed: false };
      }

      return validateServiceFields(state);
    }

    case ProcessServiceActionTypes.RESET_VALIDATION: {
      return update(state, {
        validationPassed: { $set: false },
      });
    }

    case ProcessServiceActionTypes.ADD_SERVICE_PROVIDER_FEES: {
      const serviceProviders = state.service.serviceProviders.map((serviceProvider: CreateServiceProviderInput) => {
        const { distribution: distributionOfClientFee, ...clientFees } = action.fees.clientFees;
        const { distribution: distributionOfServiceRewardFee, ...serviceRewardFees } = action.fees.serviceRewardFees;

        if (serviceProvider.serviceProviderId === action.id) {
          return {
            ...serviceProvider,
            clientFees: {
              ...serviceProvider.clientFees,
              fees: clientFees,
              distribution: distributionOfClientFee,
            },
            serviceRewardFees: {
              ...serviceProvider.serviceRewardFees,
              fees: serviceRewardFees,
              distribution: distributionOfServiceRewardFee,
            },
            validationPassed: true,
          };
        }

        return serviceProvider;
      });

      const newState = update(state, {
        service: {
          $set: update(state.service, {
            serviceProviders: { $set: serviceProviders },
          }),
        },
      });

      return ProcessServiceReducer(newState, validateService(true));
    }

    case ProcessServiceActionTypes.SET_SERVICE_INITIAL_STATE: {
      const serviceProvidersCountArray = new Array(action.service.serviceProviders.length);

      return update(state, {
        service: { $set: action.service },
        serviceErrors: {
          serviceProviders: serviceProvidersCountArray.map(() => {
            return {
              serviceId: null,
              serviceProviderId: null,
            };
          }),
        },
      });
    }

    default:
      return state;
  }
}
