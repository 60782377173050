/* External dependencies */
import gql from 'graphql-tag';

export const listServiceProvidersQuery = gql`
  query listServiceProviders($input: ListServiceProvidersInput!) {
    listServiceProviders(input: $input) {
      total
      serviceProviders {
        serviceProviderId: id
        name_en
        name_ky
        name_ru
        status
      }
    }
  }
`;
