/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UpdateUserInput } from './actions';
import {
  EmailInvalidException,
  EmailRequiredException,
  FirstNameRequiredException,
  LastNameRequiredException,
  RoleRequiredException,
  StatusRequiredException,
} from './exceptions';

export function validateForm(state, updates: UpdateUserInput) {
  const { firstName, lastName, email, role, status } = updates;

  const change: any = {
    isFormChanged: { $set: true },
  };

  if (updates.hasOwnProperty('firstName')) {
    if (firstName.length < 1) {
      change.firstNameError = { $set: new FirstNameRequiredException() };
    } else {
      change['$unset'] = ['firstNameError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          firstName: { $set: firstName },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('lastName')) {
    if (lastName.length < 1) {
      change.lastNameError = { $set: new LastNameRequiredException() };
    } else {
      change['$unset'] = ['lastNameError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          lastName: { $set: lastName },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('email')) {
    if (email.length < 1) {
      change.emailError = { $set: new EmailRequiredException() };
    } else if (!validateEmail(email)) {
      change.emailError = { $set: new EmailInvalidException() };
    } else {
      change['$unset'] = ['emailError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          email: { $set: email },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('role')) {
    if (role.length < 1) {
      change.roleError = { $set: new RoleRequiredException() };
    } else {
      change['$unset'] = ['roleError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          role: { $set: role },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('status')) {
    if (status.length < 1) {
      change.statusError = { $set: new StatusRequiredException() };
    } else {
      change['$unset'] = ['statusError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          status: { $set: status },
        }),
      },
    });
  }

  return state;
}

export function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regex.test(email);
}
