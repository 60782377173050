// External dependencies
import { filter, map, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../../clients/demirbank';
import { setServiceInitialState } from '../../processService/redux/actions';
import { getServiceProviderQuery } from '../../serviceProviders/updateServiceProvider/redux/queries';
import { Service } from '../../utils/types';
import { mapServiceFees } from '../../utils/helper';
import {
  GetServiceAction,
  GetServiceActionTypes,
  GetServiceRequest,
  GetServiceSuccess,
  getServiceFailed,
  getServiceSucceeded,
} from './actions';
import { getServiceQuery } from './queries';

export function getServiceEpic(action$) {
  return action$.pipe(
    filter((action: GetServiceAction) => action.type === GetServiceActionTypes.GET_SERVICE_REQUEST),
    switchMap((action: GetServiceRequest) =>
      getService(action)
        .then(getServiceSucceeded)
        .catch((error: Error) => getServiceFailed(error)),
    ),
  );
}

export function setServiceState(action$) {
  return action$.pipe(
    filter((action: GetServiceAction) => action.type === GetServiceActionTypes.GET_SERVICE_SUCCESS),
    map((action: GetServiceSuccess) => setServiceInitialState(action.service)),
  );
}

export async function getService(action: GetServiceRequest): Promise<Service> {
  const graphQLClient = await getClient();
  const { serviceId } = action;

  const {
    data: { getService },
  } = await graphQLClient.query({
    query: getServiceQuery,
    variables: { input: { id: serviceId } },
  });

  const service = getService as Service;

  const promises = service.serviceProviders.map(({ serviceProviderId }) => {
    return graphQLClient.query({
      query: getServiceProviderQuery,
      variables: {
        input: { id: serviceProviderId },
      },
    });
  });

  const serviceProviders = (await Promise.all(promises)).map((item) => {
    const servieProvider = item.data.getServiceProvider;

    return { ...item.data.getServiceProvider, name: servieProvider.name_en };
  });

  const serviceProvidersWithFee = service.serviceProviders.map((serviceProvider, index) => {
    return {
      ...serviceProviders[index],
      ...serviceProvider,
    };
  });

  return { ...service, serviceProviders: serviceProvidersWithFee };
}
