/* External dependencies */
import gql from 'graphql-tag';

export const getServiceQuery = gql`
  query getService($input: GetServiceInput!) {
    getService(input: $input) {
      exactAmountRequired
      logo {
        url
      }
      name_en
      name_ru
      name_ky
      minAmount {
        currency
        amount
      }
      maxAmount {
        currency
        amount
      }
      status
      statusMessage
      id
      serviceProviders {
        serviceId
        serviceProviderId
        clientFees {
          distribution {
            distribution
            dynamicDistributionSteps {
              from {
                amount
                currency
              }
              distribution {
                distribution
                flatDistribution {
                  amount
                  currency
                }
              }
            }
            flatDistribution {
              amount
              currency
            }
          }
          fees {
            flatFee {
              amount
              currency
            }
            flatFeePercent
            dynamicFeeSteps {
              from {
                amount
                currency
              }
              fee {
                flatFee {
                  amount
                  currency
                }
                flatFeePercent
              }
            }
          }
        }
        requiredFieldsMapping {
          name
          value
        }
        serviceRewardFees {
          distribution {
            distribution
            dynamicDistributionSteps {
              from {
                amount
                currency
              }
              distribution {
                distribution
                flatDistribution {
                  amount
                  currency
                }
              }
            }
            flatDistribution {
              amount
              currency
            }
          }
          fees {
            flatFee {
              amount
              currency
            }
            flatFeePercent
            dynamicFeeSteps {
              from {
                amount
                currency
              }
              fee {
                flatFee {
                  amount
                  currency
                }
                flatFeePercent
              }
            }
          }
        }
      }
    }
  }
`;
