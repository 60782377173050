/* Local dependencies */
import { UpperAndLowerFee } from '../../../fees/types';
import { ProcessService, UpdateServiceField, UpdateServiceProviderFieldsForService } from '../../utils/types';

export enum ProcessServiceActionTypes {
  ADD_SERVICE_PROVIDER_FEES = 'ADD_SERVICE_PROVIDER_FEES',
  RESET_CREATE_SERVICE_STATE = 'RESET_CREATE_SERVICE_STATE',
  RESET_ERROR = 'RESET_ERROR',
  UPDATE_SERVICE_FIELDS = 'UPDATE_CREATE_SERVICE_FIELDS',
  UPDATE_SERVICE_PROVIDER_FEES_FIELDS = 'UPDATE_CREATE_SERVICE_PROVIDER_FEES_FIELDS',
  CHANGE_SERVICE_PROVIDER_FIELDS = 'CHANGE_SERVICE_PROVIDER_FIELDS',
  ADD_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE = 'ADD_SELECT_FIELD_FOR_SERVICE_PROVIDER_CREATE_SERVICE',
  REMOVE_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE = 'REMOVE_SELECT_FIELD_FOR_SERVICE_PROVIDER_CREATE_SERVICE',
  VALIDATE_SERVICE = 'VALIDATE_SERVICE',
  RESET_VALIDATION = 'RESET_VALIDATION',
  SET_SERVICE_INITIAL_STATE = 'SET_SERVICE_INITIAL_STATE',
}

export interface ResetProcessServiceState {
  type: ProcessServiceActionTypes.RESET_CREATE_SERVICE_STATE;
}

export interface SetServiceInitialState {
  type: ProcessServiceActionTypes.SET_SERVICE_INITIAL_STATE;
  service: ProcessService;
}

export interface ResetError {
  type: ProcessServiceActionTypes.RESET_ERROR;
}

export interface ResetValidation {
  type: ProcessServiceActionTypes.RESET_VALIDATION;
}

export interface UpdateProcessServiceFields {
  type: ProcessServiceActionTypes.UPDATE_SERVICE_FIELDS;
  updates: [string, UpdateServiceField];
}

export interface ChangeServiceProviderFields {
  type: ProcessServiceActionTypes.CHANGE_SERVICE_PROVIDER_FIELDS;
  serviceProvider: UpdateServiceProviderFieldsForService;
  index: number;
}

export interface AddSelectFieldForServiceProvider {
  type: ProcessServiceActionTypes.ADD_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE;
}

export interface RemoveSelectFieldForServiceProvider {
  type: ProcessServiceActionTypes.REMOVE_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE;
  index: number;
}

export interface ValidateService {
  type: ProcessServiceActionTypes.VALIDATE_SERVICE;
  fully: boolean;
}

export interface AddServiceProviderFees {
  type: ProcessServiceActionTypes.ADD_SERVICE_PROVIDER_FEES;
  id: string;
  fees: UpperAndLowerFee;
}

export type ProcessServiceAction =
  | UpdateProcessServiceFields
  | ResetProcessServiceState
  | ResetError
  | ChangeServiceProviderFields
  | AddSelectFieldForServiceProvider
  | RemoveSelectFieldForServiceProvider
  | AddServiceProviderFees
  | ResetValidation
  | SetServiceInitialState
  | ValidateService;

export function setServiceInitialState(service: ProcessService): SetServiceInitialState {
  return {
    type: ProcessServiceActionTypes.SET_SERVICE_INITIAL_STATE,
    service,
  };
}

export function resetProcessServiceState(): ResetProcessServiceState {
  return {
    type: ProcessServiceActionTypes.RESET_CREATE_SERVICE_STATE,
  };
}

export function resetError(): ResetError {
  return {
    type: ProcessServiceActionTypes.RESET_ERROR,
  };
}

export function resetValidation(): ResetValidation {
  return {
    type: ProcessServiceActionTypes.RESET_VALIDATION,
  };
}

export function updateProcessServiceFields(updates: [string, UpdateServiceField]): UpdateProcessServiceFields {
  return {
    type: ProcessServiceActionTypes.UPDATE_SERVICE_FIELDS,
    updates,
  };
}

export function changeServiceProviderFields(
  serviceProvider: UpdateServiceProviderFieldsForService,
  index: number,
): ChangeServiceProviderFields {
  return {
    type: ProcessServiceActionTypes.CHANGE_SERVICE_PROVIDER_FIELDS,
    serviceProvider,
    index,
  };
}

export function addSelectFieldForServiceProvider(): AddSelectFieldForServiceProvider {
  return {
    type: ProcessServiceActionTypes.ADD_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE,
  };
}

export function removeSelectFieldForServiceProvider(index: number): RemoveSelectFieldForServiceProvider {
  return {
    type: ProcessServiceActionTypes.REMOVE_SELECT_FIELD_FOR_SERVICE_PROVIDER_SERVICE,
    index,
  };
}

export function addServiceProviderFee(id: string, fees: UpperAndLowerFee): AddServiceProviderFees {
  return {
    type: ProcessServiceActionTypes.ADD_SERVICE_PROVIDER_FEES,
    id,
    fees,
  };
}

export function validateService(fully = false): ValidateService {
  return {
    type: ProcessServiceActionTypes.VALIDATE_SERVICE,
    fully,
  };
}
