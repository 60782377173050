/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../../clients/demirbank';
import {
  ListServiceProviders,
  ListServiceProvidersAction,
  ListServiceProvidersActionTypes,
  ListServiceProvidersSuccess,
  listServiceProvidersFailed,
  listServiceProvidersSucceeded,
} from './actions';
import { listServiceProvidersQuery } from './queries';

export default function listServiceProvidersEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListServiceProvidersAction) =>
        action.type === ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListServiceProviders) => {
      return listServiceProviders(action, state$.value?.serviceProviders?.size).catch(listServiceProvidersFailed);
    }),
  );
}

export async function listServiceProviders(
  { currentPage, searchString, filter }: ListServiceProviders,
  size: number,
): Promise<ListServiceProvidersSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listServiceProviders: { serviceProviders, total },
    },
  } = await graphQLClient.query({
    query: listServiceProvidersQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        filter,
        query: searchString,
        size,
      },
    },
  });

  return listServiceProvidersSucceeded(total, serviceProviders);
}
