// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { RequiredException } from '../../utils/exceptions';
import { ProcessServiceErrors, ProcessServiceState } from './reducer';

export default function validateServiceFields(state: ProcessServiceState): ProcessServiceState {
  const {
    service: { status, serviceProviders, logo, statusMessage, ...service },
    serviceErrors,
  } = state;

  let serviceProvidersValidationPassed = true;
  const serviceKeys = Object.keys(service);

  let errors: ProcessServiceErrors = null;

  // validate service fields
  serviceKeys.forEach((serviceKey) => {
    if (!service[serviceKey] && serviceKey !== 'exactAmountRequired') {
      serviceProvidersValidationPassed = false;

      errors = update(errors || serviceErrors, {
        [serviceKey]: { $set: new RequiredException() },
      });
    }
  });

  // validate service providers
  serviceProviders.forEach((serviceProvider, index) => {
    if (!serviceProvider.clientFees.fees || !serviceProvider.serviceRewardFees.fees) {
      serviceProvidersValidationPassed = false;
    }

    if (!serviceProvider.serviceProviderId) {
      serviceProvidersValidationPassed = false;

      errors = update(errors || serviceErrors, {
        serviceProviders: {
          $set: update(errors?.serviceProviders || serviceErrors?.serviceProviders, {
            [index]: {
              serviceProviderId: { $set: new RequiredException() },
            },
          }),
        },
      });
    }

    if (!serviceProvider.serviceId) {
      serviceProvidersValidationPassed = false;

      errors = update(errors || serviceErrors, {
        serviceProviders: {
          $set: update(errors?.serviceProviders || serviceErrors?.serviceProviders, {
            [index]: {
              serviceId: { $set: new RequiredException() },
            },
          }),
        },
      });
    }
  });

  return { ...state, serviceErrors: errors || serviceErrors, validationPassed: serviceProvidersValidationPassed };
}
