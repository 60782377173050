// Local dependencies
import { Service } from '../../utils/types';

export enum CreateServiceActionTypes {
  CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST',
  CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS',
  CREATE_SERVICE_ERROR = 'CREATE_SERVICE_ERROR',
  RESET_CREATE_SERVICE_STATE = 'RESET_SERVICE_STATE',
  RESET_CREATE_SERVICE_ERROR = 'RESET_CREATE_SERVICE_ERROR',
}

export interface CreateServiceRequest {
  type: CreateServiceActionTypes.CREATE_SERVICE_REQUEST;
  service: Service;
}

export interface CreateServiceSuccess {
  type: CreateServiceActionTypes.CREATE_SERVICE_SUCCESS;
  service: Service;
}

export interface CreateServiceError {
  type: CreateServiceActionTypes.CREATE_SERVICE_ERROR;
  error: Error;
}

export interface ResetCreateService {
  type: CreateServiceActionTypes.RESET_CREATE_SERVICE_STATE;
}

export interface ResetCreateServiceError {
  type: CreateServiceActionTypes.RESET_CREATE_SERVICE_ERROR;
}

export type CreateServiceAction =
  | CreateServiceRequest
  | CreateServiceSuccess
  | CreateServiceError
  | ResetCreateService
  | ResetCreateServiceError;

export function createServiceRequest(service: Service): CreateServiceRequest {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_REQUEST,
    service,
  };
}

export function createServiceSucceeded(service: Service): CreateServiceSuccess {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_SUCCESS,
    service,
  };
}

export function createServiceFailed(error: Error): CreateServiceError {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_ERROR,
    error,
  };
}

export function resetCreateService() {
  return {
    type: CreateServiceActionTypes.RESET_CREATE_SERVICE_STATE,
  };
}

export function resetCreateServiceError(): ResetCreateServiceError {
  return {
    type: CreateServiceActionTypes.RESET_CREATE_SERVICE_ERROR,
  };
}
