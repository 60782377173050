// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { CallbackUrlStatus, Client } from '../../types';
import { GetAppClientActions, GetAppClientActionTypes } from './actions';

export interface GetAppClientState {
  error?: Error;
  loading: boolean;
  client?: Client;
  callbackUrlStatus: CallbackUrlStatus;
}

export const initialGetAppClientState: GetAppClientState = {
  loading: false,
  callbackUrlStatus: CallbackUrlStatus.NOT_VALIDATED,
};

export default function getAppClientReducer(state = initialGetAppClientState, action: GetAppClientActions) {
  switch (action.type) {
    case GetAppClientActionTypes.GET_APP_CLIENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetAppClientActionTypes.GET_APP_CLIENT_SUCCESS:
      return update(state, {
        client: { $set: action.client },
        loading: { $set: false },
      });

    case GetAppClientActionTypes.GET_APP_CLIENT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetAppClientActionTypes.VALIDATE_CALLBACK_URL:
      if (state.client?.callbackUrls.includes(action.url)) {
        return update(state, {
          callbackUrlStatus: { $set: CallbackUrlStatus.VALIDATED },
        });
      }

      return update(state, {
        callbackUrlStatus: { $set: CallbackUrlStatus.ERROR },
      });

    default:
      return state;
  }
}
