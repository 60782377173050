// External dependencies
import gql from 'graphql-tag';

export const adminCreateUserMutation = gql`
  mutation adminCreateUser($input: CreateUserInput!) {
    adminCreateUser(input: $input) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;
