// Local dependencies
import { Client } from '../../types';

export enum GetAppClientActionTypes {
  GET_APP_CLIENT_REQUEST = 'GET_APP_CLIENT_REQUEST',
  GET_APP_CLIENT_SUCCESS = 'GET_APP_CLIENT_SUCCESS',
  GET_APP_CLIENT_ERROR = 'GET_APP_CLIENT_ERROR',
  VALIDATE_CALLBACK_URL = 'VALIDATE_CALLBACK_URL',
}

export interface GetAppClient {
  type: GetAppClientActionTypes.GET_APP_CLIENT_REQUEST;
  id: string;
}

export interface GetAppClientSuccess {
  type: GetAppClientActionTypes.GET_APP_CLIENT_SUCCESS;
  client: Client;
}

export interface GetAppClientError {
  type: GetAppClientActionTypes.GET_APP_CLIENT_ERROR;
  error: Error;
}

export interface ValidateCallbackUrl {
  type: GetAppClientActionTypes.VALIDATE_CALLBACK_URL;
  url: string;
}

export type GetAppClientActions = GetAppClient | GetAppClientSuccess | GetAppClientError | ValidateCallbackUrl;

export function getAppClientRequest(id: string): GetAppClient {
  return {
    type: GetAppClientActionTypes.GET_APP_CLIENT_REQUEST,
    id,
  };
}

export function getAppClientSucceeded(client: Client): GetAppClientSuccess {
  return {
    type: GetAppClientActionTypes.GET_APP_CLIENT_SUCCESS,
    client,
  };
}

export function getAppClientFailed(error: Error): GetAppClientError {
  return {
    type: GetAppClientActionTypes.GET_APP_CLIENT_ERROR,
    error,
  };
}

export function validateCallbackUrl(url: string): ValidateCallbackUrl {
  return {
    type: GetAppClientActionTypes.VALIDATE_CALLBACK_URL,
    url,
  };
}
