// Local dependencies
import { Service } from '../../utils/types';
import { CreateServiceAction, CreateServiceActionTypes } from './actions';

export interface CreateServiceState {
  loading: boolean;
  isSuccess: boolean;
  service: Service;
  error: Error;
}

export const createServiceInitialState: CreateServiceState = {
  loading: false,
  isSuccess: false,
  service: null,
  error: null,
};

export default function createServiceReducer(state = createServiceInitialState, action: CreateServiceAction) {
  switch (action.type) {
    case CreateServiceActionTypes.CREATE_SERVICE_REQUEST: {
      return { ...state, loading: true };
    }

    case CreateServiceActionTypes.CREATE_SERVICE_SUCCESS: {
      return { ...state, loading: false, isSuccess: true };
    }

    case CreateServiceActionTypes.CREATE_SERVICE_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    case CreateServiceActionTypes.RESET_CREATE_SERVICE_STATE: {
      return createServiceInitialState;
    }

    case CreateServiceActionTypes.RESET_CREATE_SERVICE_ERROR: {
      return { ...state, error: null };
    }

    default: {
      return createServiceInitialState;
    }
  }
}
