/* External dependencies */
import gql from 'graphql-tag';

export const listUsersQuery = gql`
  query listUsers($input: ListUsersInput) {
    listUsers(input: $input) {
      total
      users {
        firstName
        id
        lastName
        email
        role
        status
      }
    }
  }
`;
