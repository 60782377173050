/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../../clients/demirbank';
import { cleanPayload } from '../../../../common/helpers';
import { ServiceProvider } from '../../../utils/types';
import {
  CreateServiceProviderAction,
  CreateServiceProviderActionTypes,
  createServiceProviderFailed,
  CreateServiceProviderRequest,
  createServiceProviderSucceeded,
} from './actions';
import { createServiceProviderMutation } from './mutation';

export default function createServiceProviderEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateServiceProviderAction) =>
        action.type === CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_REQUEST
    ),
    switchMap((action: CreateServiceProviderRequest) =>
      createServiceProvider(action).then(createServiceProviderSucceeded).catch(createServiceProviderFailed)
    )
  );
}

export async function createServiceProvider(action: CreateServiceProviderRequest): Promise<ServiceProvider> {
  const graphQLClient = await getClient();
  const { serviceProvider } = action;

  const {
    data: { createServiceProvider },
  } = await graphQLClient.mutate({
    mutation: createServiceProviderMutation,
    variables: {
      input: cleanPayload(serviceProvider),
    },
  });

  return createServiceProvider as ServiceProvider;
}
