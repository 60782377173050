// Local dependencies
import { Service } from '../../utils/types';

export enum GetServiceActionTypes {
  GET_SERVICE_REQUEST = 'GET_SERVICE_REQUEST',
  GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS',
  GET_SERVICE_ERROR = 'GET_SERVICE_ERROR',
  RESET_GET_SERVICE_STATE = 'RESET_GET_SERVICE_STATE',
  RESET_GET_SERVICE_ERROR = 'RESET_GET_SERVICE_ERROR',
}

export interface GetServiceRequest {
  type: GetServiceActionTypes.GET_SERVICE_REQUEST;
  serviceId: string;
}

export interface GetServiceSuccess {
  type: GetServiceActionTypes.GET_SERVICE_SUCCESS;
  service: Service;
}

export interface GetServiceError {
  type: GetServiceActionTypes.GET_SERVICE_ERROR;
  error: Error;
}

export interface ResetGetService {
  type: GetServiceActionTypes.RESET_GET_SERVICE_STATE;
}

export interface ResetGetServiceError {
  type: GetServiceActionTypes.RESET_GET_SERVICE_ERROR;
}

export type GetServiceAction =
  | GetServiceRequest
  | GetServiceSuccess
  | GetServiceError
  | ResetGetService
  | ResetGetServiceError;

export function getServiceRequest(serviceId: string): GetServiceRequest {
  return {
    type: GetServiceActionTypes.GET_SERVICE_REQUEST,
    serviceId,
  };
}

export function getServiceSucceeded(service: Service): GetServiceSuccess {
  return {
    type: GetServiceActionTypes.GET_SERVICE_SUCCESS,
    service,
  };
}

export function getServiceFailed(error: Error): GetServiceError {
  return {
    type: GetServiceActionTypes.GET_SERVICE_ERROR,
    error,
  };
}

export function resetGetService(): ResetGetService {
  return {
    type: GetServiceActionTypes.RESET_GET_SERVICE_STATE,
  };
}

export function resetGetServiceError(): ResetGetServiceError {
  return {
    type: GetServiceActionTypes.RESET_GET_SERVICE_ERROR,
  };
}
