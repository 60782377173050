export enum ActiveServiceActionTypes {
  ACTIVE_SERVICE_REQUEST = 'ACTIVE_SERVICE_REQUEST',
  ACTIVE_SERVICE_SUCCESS = 'ACTIVE_SERVICE_SUCCESS',
  ACTIVE_SERVICE_ERROR = 'ACTIVE_SERVICE_ERROR',
  RESET_ACTIVE_SERVICE_STATE = 'RESET_ACTIVE_SERVICE_STATE',
}

export interface ActiveServiceRequest {
  type: ActiveServiceActionTypes.ACTIVE_SERVICE_REQUEST;
  id: string;
}

export interface ActiveServiceSuccess {
  type: ActiveServiceActionTypes.ACTIVE_SERVICE_SUCCESS;
}

export interface ActiveServiceError {
  type: ActiveServiceActionTypes.ACTIVE_SERVICE_ERROR;
  error: Error;
}

export interface ResetActiveServiceState {
  type: ActiveServiceActionTypes.RESET_ACTIVE_SERVICE_STATE;
}

export type ActiveServiceAction =
  | ActiveServiceRequest
  | ActiveServiceSuccess
  | ActiveServiceError
  | ResetActiveServiceState;

export function activeServiceRequest(id: string): ActiveServiceRequest {
  return {
    type: ActiveServiceActionTypes.ACTIVE_SERVICE_REQUEST,
    id,
  };
}

export function activeServiceSuccessed(): ActiveServiceSuccess {
  return {
    type: ActiveServiceActionTypes.ACTIVE_SERVICE_SUCCESS,
  };
}

export function activeServiceError(error: Error): ActiveServiceError {
  return {
    type: ActiveServiceActionTypes.ACTIVE_SERVICE_ERROR,
    error,
  };
}

export function resetActiveServiceState(): ResetActiveServiceState {
  return {
    type: ActiveServiceActionTypes.RESET_ACTIVE_SERVICE_STATE,
  };
}
