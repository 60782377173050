/* Local dependencies */
import { UpdateUserInput } from '../../createUser/redux/actions';

export enum UserActionTypes {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_FAILURE = 'GET_USER_FAILURE',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  RESET_USER_DETAILS = 'RESET_USER_DETAILS',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE',
  UPDATE_USER_FIELDS = 'UPDATE_USER_FIELDS',
  RESET_USER_DETAILS_ERROR = 'RESET_USER_DETAILS_ERROR',
}

export interface GetUser {
  type: UserActionTypes.GET_USER_REQUEST;
  id: string;
}

export interface GetUserSuccess {
  type: UserActionTypes.GET_USER_SUCCESS;
  user: UpdateUserInput;
}

export interface GetUserFailure {
  type: UserActionTypes.GET_USER_FAILURE;
  error: Error;
}

export interface ResetUserDetails {
  type: UserActionTypes.RESET_USER_DETAILS;
}

export interface ResetUserDetailsError {
  type: UserActionTypes.RESET_USER_DETAILS_ERROR;
}

export interface UpdateUser {
  type: UserActionTypes.UPDATE_USER_REQUEST;
  user: UpdateUserInput;
}

export interface UpdateUserSuccess {
  type: UserActionTypes.UPDATE_USER_SUCCESS;
  user: UpdateUserInput;
}

export interface UpdateUserFailure {
  type: UserActionTypes.UPDATE_USER_FAILURE;
  error: Error;
}

export interface UserFieldsUpdate {
  type: UserActionTypes.UPDATE_USER_FIELDS;
  updates: UpdateUserInput;
}

export type UserActions =
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | ResetUserDetails
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailure
  | UserFieldsUpdate
  | ResetUserDetailsError;

export function getUser(id: string): GetUser {
  return {
    type: UserActionTypes.GET_USER_REQUEST,
    id,
  };
}

export function getUserSucceeded(user: UpdateUserInput): GetUserSuccess {
  return {
    type: UserActionTypes.GET_USER_SUCCESS,
    user,
  };
}

export function getUserFailed(error: Error): GetUserFailure {
  return {
    type: UserActionTypes.GET_USER_FAILURE,
    error,
  };
}

export function resetUserDetails(): ResetUserDetails {
  return {
    type: UserActionTypes.RESET_USER_DETAILS,
  };
}

export function resetUserDetailsError(): ResetUserDetailsError {
  return {
    type: UserActionTypes.RESET_USER_DETAILS_ERROR,
  };
}

export function updateUser(user: UpdateUserInput): UpdateUser {
  return {
    type: UserActionTypes.UPDATE_USER_REQUEST,
    user,
  };
}

export function updateUserSuccess(user: UpdateUserInput): UpdateUserSuccess {
  return {
    type: UserActionTypes.UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserFailure(error: Error): UpdateUserFailure {
  return {
    type: UserActionTypes.UPDATE_USER_FAILURE,
    error,
  };
}

export function userFieldsUpdate(updates): UserFieldsUpdate {
  return {
    type: UserActionTypes.UPDATE_USER_FIELDS,
    updates,
  };
}
