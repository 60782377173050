/* Local dependencies */
import { SessionUser } from '../login/redux/actions';
import * as roles from './roles';
import UserRole = roles.UserRole;

export const QUANTITY_TERMINALS = ['1900+', '1 900+', '1,900', '1900'];
export const QUANTITY_USERS = ['630,000'];
export const QUANTITY_TURNOVER = ['1.8'];

export function getUserRole(currentUser: SessionUser) {
  if (!currentUser?.roles) {
    if (currentUser?.hasOwnProperty('cognito:preferred_role')) {
      currentUser.roles = roles.extractRoleFromARN(currentUser['cognito:preferred_role']);
    }
  }

  return currentUser?.roles;
}

export function isAccountant(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.ACCOUNTANT;
}

export function isAdmin(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.ADMIN;
}

export function isOperator(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.OPERATOR;
}

export function isManager(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.MANAGER;
}

export function isDealer(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRole.DEALER;
}

/*
 * Cleans up the payload before sending to the server because
 * GraphQL does not accept undefined attributes.
 */
export function cleanPayload(payload: object) {
  Object.keys(payload).forEach((key: string) => {
    if (['__typename'].includes(key) || payload[key] === null || payload[key] === '' || ['typename'].includes(key)) {
      payload[key] = undefined;
    }

    if (typeof payload[key] === 'object') {
      payload[key] = cleanPayload(payload[key]);
    }
  });

  return payload;
}

export function cleanUndefinedValuesFromPayload(payload: object) {
  const input = Object.keys(payload).reduce((input, key) => {
    if (payload.hasOwnProperty(key) && typeof payload[key] !== 'undefined') {
      input[key] = payload[key];
    }

    return input;
  }, {});

  return input;
}

export function removeSpaces(payload: any) {
  for (const [key, value] of Object.entries(payload)) {
    switch (true) {
      case ['__typename', 'typename'].includes(key):
      case value === null || value === '':
        payload[key] = undefined;
        break;
      case typeof value === 'string':
        payload[key] = (value as string).trim();
        break;
      case typeof value === 'object':
        payload[key] = removeSpaces(value);
        break;
      default:
        break;
    }
  }

  return payload;
}

export function isMobile(): boolean {
  return typeof window !== 'undefined' ? window.innerWidth < 650 : false;
}

export const isBrowser = typeof window !== 'undefined';

export function setLocalStorage(key: string, value: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export function setSessionStorage(key: string, value: any) {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(key, value);
  }
}

export function getItemFromLocalStorage(key: string) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key);
  }

  return JSON.stringify({ mockData: 'mockData' });
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function capitalizeFirstLetter(country: string) {
  return country.charAt(0).toUpperCase() + country.slice(1);
}

export function createSlug(string) {
  return string
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+|-$/g, '');
}

export function keyPressOnInputs(inputs: any) {
  inputs.forEach((input) => {
    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        const currentInputIndex = [...inputs].indexOf(e.target);
        const nextInput = inputs[currentInputIndex + 1];

        if (nextInput) {
          nextInput.focus();
        }
      }
    });
  });
}

export function splitString(str) {
  let characters = [];
  let separators = [];
  let currentCharacterCount = 0;

  for (let i = 0; i < str?.length; i++) {
    if (/[a-zA-Z0-9+]/.test(str[i])) {
      currentCharacterCount++;
    } else {
      if (currentCharacterCount > 0) {
        characters.push(currentCharacterCount);
        currentCharacterCount = 0;
      }

      separators.push(str[i]);
    }
  }

  if (currentCharacterCount > 0) {
    characters.push(currentCharacterCount);
  }

  return [characters, separators];
}
