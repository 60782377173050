/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { CreateUserAction, CreateUserActionTypes, User } from './actions';
import { validateForm } from './validation';

export interface UsersState {
  error?: Error;
  firstNameError?: Error;
  lastNameError?: Error;
  loading?: boolean;
  emailError?: Error;
  roleError?: Error;
  user: User;
}

const initialUsersState: UsersState = {
  user: {},
};

export default function newUser(state = initialUsersState, action: CreateUserAction) {
  switch (action.type) {
    case CreateUserActionTypes.CREATE_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateUserActionTypes.CREATE_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        user: { $set: action.user },
      });

    case CreateUserActionTypes.CREATE_USER_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case CreateUserActionTypes.CREATE_USER_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case CreateUserActionTypes.RESET_CREATE_USER:
      return update(state, { $set: initialUsersState });

    case CreateUserActionTypes.UPDATE_USER_FIELDS:
      return validateForm(state, action.updates);

    default:
      return state;
  }
}
