import { Session } from '../../../clients/cognito';
import CustomCognitoUserSession from '../../../clients/cognitoUserSession';
import { UserRole } from '../../common/roles';

export enum LoginActionTypes {
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  INIT_CLIENT = 'INIT_CLIENT',
  INIT_CLIENT_FAILED = 'INIT_CLIENT_FAILED',
  INIT_CLIENT_SUCCEEDED = 'INIT_CLIENT_SUCCEEDED',
  INPUT_VERIFICATION_CODE = 'INPUT_VERIFICATION_CODE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_NEW_PASSWORD_REQUIRED = 'LOGIN_NEW_PASSWORD_REQUIRED',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGOUT_ERROR = 'LOGOUT_ERROR',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  REQUEST_VERIFICATION_CODE = 'REQUEST_VERIFICATION_CODE',
  REMEMBERED_PASSWORD = 'REMEMBERED_PASSWORD',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  SET_USERNAME = 'SET_USERNAME',
  VERIFY_CODE = 'VERIFY_CODE',
}

export function inputVerificationCode(): InputVerificationCode {
  return {
    type: LoginActionTypes.INPUT_VERIFICATION_CODE,
  };
}

export function confirmPassword(newPassword: string, username: string): ConfirmPassword {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD,
    newPassword,
    username,
  };
}

export function initClient(): InitClient {
  return {
    type: LoginActionTypes.INIT_CLIENT,
  };
}

export function initClientFailed(): InitClientFailed {
  return {
    type: LoginActionTypes.INIT_CLIENT_FAILED,
  };
}

export function initClientSucceeded(session: CustomCognitoUserSession): InitClientSucceeded {
  return {
    type: LoginActionTypes.INIT_CLIENT_SUCCEEDED,
    session,
  };
}

export function forgotPassword(username: string): ForgotPassword {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD,
    username,
  };
}

export function requestVerificationCode(username: string): RequestVerificationCode {
  return {
    type: LoginActionTypes.REQUEST_VERIFICATION_CODE,
    username,
  };
}

export function rememberedPassword(username: string): RememberedPassword {
  return {
    type: LoginActionTypes.REMEMBERED_PASSWORD,
    username,
  };
}

export function verifyCode(verificationCode: number): VerifyCode {
  return {
    type: LoginActionTypes.VERIFY_CODE,
    verificationCode,
  };
}

export function logout(): LogoutRequest {
  return {
    type: LoginActionTypes.LOGOUT_REQUEST,
  };
}

export function login(username: string, password: string): LoginRequest {
  return {
    type: LoginActionTypes.LOGIN_REQUEST,
    password,
    username,
  };
}

export function newPasswordRequired(sessionKey, userAttributes): NewPasswordRequired {
  return {
    type: LoginActionTypes.LOGIN_NEW_PASSWORD_REQUIRED,
    sessionKey,
    userAttributes,
  };
}

export function loginFailed(error: Error): LoginError {
  return {
    type: LoginActionTypes.LOGIN_ERROR,
    error,
  };
}

export function logoutFailed(error: Error): LogoutError {
  return {
    type: LoginActionTypes.LOGOUT_ERROR,
    error,
  };
}

export function changePassword(password: string, username: string): ChangePassword {
  return {
    type: LoginActionTypes.SET_NEW_PASSWORD,
    password,
    username,
  };
}

export function logoutSucceeded(): LogoutSuccess {
  return {
    type: LoginActionTypes.LOGOUT_SUCCESS,
  };
}

export interface LogoutRequest {
  type: LoginActionTypes.LOGOUT_REQUEST;
}

export interface LogoutError {
  type: LoginActionTypes.LOGOUT_ERROR;
  error: Error;
}

export interface LogoutSuccess {
  type: LoginActionTypes.LOGOUT_SUCCESS;
}

export interface NewPasswordRequired {
  type: LoginActionTypes.LOGIN_NEW_PASSWORD_REQUIRED;
  sessionKey: string;
  userAttributes: Session;
}

export interface LoginRequest {
  type: LoginActionTypes.LOGIN_REQUEST;
  password: string;
  username: string;
}

export interface LoginError {
  type: LoginActionTypes.LOGIN_ERROR;
  error: Error;
}

export interface LogoutError {
  type: LoginActionTypes.LOGOUT_ERROR;
  error: Error;
}

export interface ChangePassword {
  type: LoginActionTypes.SET_NEW_PASSWORD;
  password: string;
  username: string;
}

export interface ChangePasswordWithUserAttributes {
  password: string;
  sessionKey: string;
  username: string;
  userAttributes: Session;
}

export interface CognitoConfirmPassword {
  newPassword: string;
  username: string;
  verificationCode: number;
}

export interface InputVerificationCode {
  type: LoginActionTypes.INPUT_VERIFICATION_CODE;
}

export interface ConfirmPassword {
  type: LoginActionTypes.CONFIRM_PASSWORD;
  newPassword: string;
  username: string;
}

export interface InitClient {
  type: LoginActionTypes.INIT_CLIENT;
}

export interface InitClientFailed {
  type: LoginActionTypes.INIT_CLIENT_FAILED;
}

export interface InitClientSucceeded {
  type: LoginActionTypes.INIT_CLIENT_SUCCEEDED;
  session: CustomCognitoUserSession;
}

export interface ForgotPassword {
  type: LoginActionTypes.FORGOT_PASSWORD;
  username: string;
}

export interface RequestVerificationCode {
  type: LoginActionTypes.REQUEST_VERIFICATION_CODE;
  username: string;
}

export interface RememberedPassword {
  type: LoginActionTypes.REMEMBERED_PASSWORD;
  username: string;
}

export interface VerifyCode {
  type: LoginActionTypes.VERIFY_CODE;
  verificationCode: number;
}

export type LoginAction =
  | ChangePassword
  | ConfirmPassword
  | InitClient
  | InitClientFailed
  | InitClientSucceeded
  | InputVerificationCode
  | ForgotPassword
  | LoginRequest
  | LoginError
  | NewPasswordRequired
  | RequestVerificationCode
  | RememberedPassword
  | VerifyCode
  | LogoutRequest
  | LogoutSuccess
  | LogoutError;

export interface SessionUser {
  aud;
  auth_time;
  'cognito:preferred_role': string;
  email;
  email_verified;
  event_id;
  exp;
  iat;
  iss;
  groups: UserRole;
  jti;
  origin_jti;
  phone_number;
  phone_number_verified;
  roles: UserRole;
  sub;
  token_use;
}
