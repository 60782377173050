/* Local dependencies */
import { UpdateServiceProviderFields, CreateServiceProvider } from '../../../utils/types';

export enum CreateServiceProviderActionTypes {
  CREATE_SERVICE_PROVIDER_REQUEST = 'CREATE_SERVICE_PROVIDER_REQUEST',
  CREATE_SERVICE_PROVIDER_SUCCESS = 'CREATE_SERVICE_PROVIDER_SUCCESS',
  CREATE_SERVICE_PROVIDER_ERROR = 'CREATE_SERVICE_PROVIDER_ERROR',
  RESET_ERROR_CREATE_SERVICE_PROVIDER = 'RESET_ERROR_CREATE_SERVICE_PROVIDER',
  RESET_CREATE_SERVICE_PROVIDER_STATE = 'RESET_CREATE_SERVICE_PROVIDER_STATE',
  ADD_CREATE_SERVICE_PROVIDER_FIELDS = 'ADD_CREATE_SERVICE_PROVIDER_FIELDS',
  ADD_BACK_PATH_CREATE_SERVICE_PROVIDER = 'ADD_BACK_PATH_CREATE_SERVICE_PROVIDER',
}

export interface CreateServiceProviderRequest {
  type: CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_REQUEST;
  serviceProvider: CreateServiceProvider;
}

export interface CreateServiceProviderSuccess {
  type: CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_SUCCESS;
  serviceProvider: CreateServiceProvider;
}

export interface CreateServiceProviderError {
  type: CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_ERROR;
  error: Error;
}

export interface ResetErrorCreateServiceProvider {
  type: CreateServiceProviderActionTypes.RESET_ERROR_CREATE_SERVICE_PROVIDER;
}

export interface ResetCreateServiceProviderState {
  type: CreateServiceProviderActionTypes.RESET_CREATE_SERVICE_PROVIDER_STATE;
}

export interface AddServiceProviderFields {
  type: CreateServiceProviderActionTypes.ADD_CREATE_SERVICE_PROVIDER_FIELDS;
  fields: UpdateServiceProviderFields;
}

export interface AddBackPathCreateServiceProvider {
  type: CreateServiceProviderActionTypes.ADD_BACK_PATH_CREATE_SERVICE_PROVIDER;
  backPath: string;
}

export type CreateServiceProviderAction =
  | CreateServiceProviderRequest
  | CreateServiceProviderSuccess
  | CreateServiceProviderError
  | ResetErrorCreateServiceProvider
  | ResetCreateServiceProviderState
  | AddServiceProviderFields
  | AddBackPathCreateServiceProvider;

export function createServiceProviderRequest(serviceProvider: CreateServiceProvider): CreateServiceProviderRequest {
  return {
    type: CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_REQUEST,
    serviceProvider,
  };
}

export function createServiceProviderSucceeded(serviceProvider: CreateServiceProvider): CreateServiceProviderSuccess {
  return {
    type: CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_SUCCESS,
    serviceProvider,
  };
}

export function createServiceProviderFailed(error: Error): CreateServiceProviderError {
  return {
    type: CreateServiceProviderActionTypes.CREATE_SERVICE_PROVIDER_ERROR,
    error,
  };
}

export function resetErrorCreateServiceProvider(): ResetErrorCreateServiceProvider {
  return {
    type: CreateServiceProviderActionTypes.RESET_ERROR_CREATE_SERVICE_PROVIDER,
  };
}

export function resetCreateServiceProviderState(): ResetCreateServiceProviderState {
  return {
    type: CreateServiceProviderActionTypes.RESET_CREATE_SERVICE_PROVIDER_STATE,
  };
}

export function addCreateServiceProviderFields(fields: UpdateServiceProviderFields): AddServiceProviderFields {
  return {
    type: CreateServiceProviderActionTypes.ADD_CREATE_SERVICE_PROVIDER_FIELDS,
    fields,
  };
}

export function addBackPathCreateServiceProvider(backPath: string): AddBackPathCreateServiceProvider {
  return {
    type: CreateServiceProviderActionTypes.ADD_BACK_PATH_CREATE_SERVICE_PROVIDER,
    backPath,
  };
}
