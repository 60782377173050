/* Local dependencies */
import { Service, ServiceStatus } from '../utils/types';

export enum ListServicesActionTypes {
  LIST_SERVICES_REQUEST = 'LIST_SERVICES_REQUEST',
  LIST_SERVICES_SUCCESS = 'LIST_SERVICES_SUCCESS',
  LIST_SERVICES_ERROR = 'LIST_SERVICES_ERROR',
  RESET_SELECTED_SERVICE = 'RESET_SELECTED_SERVICE',
  SELECT_SERVICE = 'SELECT_SERVICE',
  SORT_SERVICES_BY = 'SORT_SERVICES_BY',
  RESET_SERVICES_STATE = 'RESET_SERVICES_STATE',
  PRESS_SERVICE = 'PRESS_SERVICE',
  RESET_PRESS_SERVICE = 'RESET_PRESS_SERVICE',
  GET_LOCALE = 'GET_LOCALE',
  DELETE_SERVICE_POPUP = 'DELETE_SERVICE_POPUP',
}

export interface ListServices {
  type: ListServicesActionTypes.LIST_SERVICES_REQUEST;
  currentPage?: number;
  filter?: ListServicesFilter;
  searchString?: string;
  size?: number;
}

export interface ListServicesSuccess {
  type: ListServicesActionTypes.LIST_SERVICES_SUCCESS;
  services: Service[];
  total: number;
}

export interface ListServicesError {
  type: ListServicesActionTypes.LIST_SERVICES_ERROR;
  error: Error;
}

export interface ListServicesFilter {
  statuses?: [ServiceStatus];
}

export const DEFAULT_SERVICE_FILTER: ListServicesFilter = {};

export interface ListServicesSort {
  type: ListServicesActionTypes.SORT_SERVICES_BY;
  field: string;
}

export interface SelectService {
  type: ListServicesActionTypes.SELECT_SERVICE;
  service: Service;
}

export interface ResetSelectedService {
  type: ListServicesActionTypes.RESET_SELECTED_SERVICE;
}

export interface ResetServicesState {
  type: ListServicesActionTypes.RESET_SERVICES_STATE;
}

export interface PressService {
  type: ListServicesActionTypes.PRESS_SERVICE;
  service: Service;
}

export interface ResetPressService {
  type: ListServicesActionTypes.RESET_PRESS_SERVICE;
}

export interface GetLocale {
  type: ListServicesActionTypes.GET_LOCALE;
  locale: string;
}

export type ListServicesAction =
  | GetLocale
  | ListServices
  | ListServicesError
  | ListServicesSuccess
  | PressService
  | ResetPressService
  | ResetSelectedService
  | ResetServicesState
  | SelectService;

export function listServices(
  filter?: {},
  currentPage: number = 1,
  searchString: string = '',
  size: number = 20,
): ListServices {
  return {
    type: ListServicesActionTypes.LIST_SERVICES_REQUEST,
    filter,
    currentPage,
    searchString,
    size,
  };
}

export function listServicesSucceeded(total, services): ListServicesSuccess {
  return {
    type: ListServicesActionTypes.LIST_SERVICES_SUCCESS,
    services,
    total,
  };
}

export function listServicesFailed(error: Error): ListServicesError {
  return {
    type: ListServicesActionTypes.LIST_SERVICES_ERROR,
    error,
  };
}

export function sortServicesBy(field: string) {
  return {
    type: ListServicesActionTypes.SORT_SERVICES_BY,
    field,
  };
}

export function selectService(service: Service): SelectService {
  return {
    type: ListServicesActionTypes.SELECT_SERVICE,
    service,
  };
}

export function resetSelectedService() {
  return {
    type: ListServicesActionTypes.RESET_SELECTED_SERVICE,
  };
}

export function resetServicesState() {
  return {
    type: ListServicesActionTypes.RESET_SERVICES_STATE,
  };
}

export function pressedService(service: Service): PressService {
  return {
    type: ListServicesActionTypes.PRESS_SERVICE,
    service,
  };
}

export function resetPressedService(): ResetPressService {
  return {
    type: ListServicesActionTypes.RESET_PRESS_SERVICE,
  };
}

export function getLocale(locale: string): GetLocale {
  return {
    type: ListServicesActionTypes.GET_LOCALE,
    locale,
  };
}
