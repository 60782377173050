/* External dependencies */
import * as AWSCognito from 'amazon-cognito-identity-js';

/* Local dependencies */
import {
  ChangePasswordWithUserAttributes,
  CognitoConfirmPassword,
  InputVerificationCode,
  LoginAction,
  LoginActionTypes,
  LoginRequest,
  NewPasswordRequired,
  RequestVerificationCode,
  SessionUser,
  inputVerificationCode,
  login,
  newPasswordRequired,
  initClientSucceeded,
  InitClientSucceeded,
} from '../components/login/redux/actions';
import CustomCognitoUserSession from './cognitoUserSession';

export const config = {
  APPSYNC_ENDPOINT: process.env.GATSBY_APPSYNC_ENDPOINT!,
  AVERSPAY_USER_ID: process.env.GATSBY_AVERSPAY_USER_ID!,
  COGNITO_CLIENT_ID: process.env.GATSBY_COGNITO_CLIENT_ID!,
  COGNITO_IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID!,
  COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID!,
  REGION: process.env.GATSBY_REGION!,
};

export const COGNITO_USER_POOL_DATA = {
  UserPoolId: config.COGNITO_USER_POOL_ID,
  ClientId: config.COGNITO_CLIENT_ID,
};

interface Token {
  payload: SessionUser;
  jwtToken: string;
}

interface RefreshToken {
  token: string;
}
export interface Session {
  accessToken?: Token;
  email: string;
  email_verified: Boolean;
  family_name: string;
  given_name: string;
  idToken?: Token;
  newPasswordRequired?: Boolean;
  phone_number: Number;
  phone_number_verified: Boolean;
  refreshToken?: RefreshToken;
}

const asyncAuthenticateUser = (cognitoUser, cognitoAuthenticationDetails): Promise<CustomCognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
      onSuccess: (session) => {
        resolve(session);
      },
      onFailure: reject,
      newPasswordRequired: function (userAttributes) {
        reject(newPasswordRequired(cognitoUser.Session, userAttributes));
      },
    });
  });
};

const completeNewPasswordChallenge = (
  cognitoUser,
  userAttributes,
  newPassword,
  sessionKey,
): Promise<CustomCognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const { given_name, family_name } = userAttributes;

    cognitoUser.Session = sessionKey;

    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {
        given_name: given_name || 'Имя',
        family_name: family_name || 'Фамилия',
      },
      {
        onFailure: reject,
        onSuccess: resolve,
      },
    );
  });
};

const completeConfirmPassword = (cognitoUser, newPassword, verificationCode): Promise<CustomCognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure: reject,
      onSuccess: resolve,
    });
  });
};

const handleForgotPassword = (cognitoUser): Promise<CustomCognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
      inputVerificationCode: function () {
        reject(inputVerificationCode());
      },
    });
  });
};

export function getSessionUser(idToken: Token | AWSCognito.CognitoIdToken) {
  return idToken.payload;
}

export async function cognitoSignIn({ username, password }: LoginRequest): Promise<LoginAction> {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);

  const cognitoAuthenticationDetails = new AWSCognito.AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const userData = { Username: username, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  try {
    const session: CustomCognitoUserSession = await asyncAuthenticateUser(cognitoUser, cognitoAuthenticationDetails);

    return initClientSucceeded(session);
  } catch (err) {
    if (err.type === LoginActionTypes.LOGIN_NEW_PASSWORD_REQUIRED) {
      // `NewPasswordRequired` is an action, that's why we are returning it directly.
      return err as NewPasswordRequired;
    }

    throw err;
  }
}

export async function cognitoChangePassword({
  password,
  username,
  userAttributes,
  sessionKey,
}: ChangePasswordWithUserAttributes): Promise<LoginAction> {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);
  const userData = { Username: username, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  const session = await completeNewPasswordChallenge(cognitoUser, userAttributes, password, sessionKey);

  return initClientSucceeded(session);
}

export async function cognitoForgotPassword({ username }: RequestVerificationCode) {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);
  const userData = { Username: username, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  try {
    const session = await handleForgotPassword(cognitoUser);

    return initClientSucceeded(session);
  } catch (err) {
    if (err.type === LoginActionTypes.INPUT_VERIFICATION_CODE) {
      // `InputVerificationCode` is an action, that's why we are returning it directly.
      return err as InputVerificationCode;
    }

    throw err;
  }
}

export async function cognitoConfirmPassword({
  newPassword,
  username,
  verificationCode,
}: CognitoConfirmPassword): Promise<LoginAction> {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);
  const userData = { Username: username, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  await completeConfirmPassword(cognitoUser, newPassword, verificationCode);

  return cognitoSignIn(login(username, newPassword));
}

export async function cognitoSignOut() {
  return new Promise((resolve, reject) => {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);
    const cognitoUser = cognitoUserPool.getCurrentUser();

    cognitoUser.getSession(function (err, result) {
      if (err) {
        return reject(err);
      }

      if (!result) {
        return resolve(null);
      }

      cognitoUser?.signOut(() => resolve(null));
    });
  });
}

export async function getSession(userPool = COGNITO_USER_POOL_DATA): Promise<CustomCognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(userPool);
    const cognitoUser = cognitoUserPool.getCurrentUser();

    cognitoUser.getSession(function (err, session) {
      if (err) {
        return reject(err);
      }

      resolve(session);
    });
  });
}
