/* Local dependencies */
import { GetServiceProviderServiceInput, ServiceProviderService } from '../../../utils/types';

export enum GetServiceProviderServiceActionTypes {
  GET_SERVICE_PROVIDER_SERVICE_REQUEST = 'GET_SERVICE_PROVIDER_SERVICE_REQUEST',
  GET_SERVICE_PROVIDER_SERVICE_SUCCESS = 'GET_SERVICE_PROVIDER_SERVICE_SUCCESS',
  GET_SERVICE_PROVIDER_SERVICE_ERROR = 'GET_SERVICE_PROVIDER_SERVICE_ERROR',
  RESET_GET_SERVICE_PROVIDER_SERVICE_STATE = 'RESET_GET_SERVICE_PROVIDER_SERVICE_STATE',
  RESET_GET_SERVICE_PROVIDER_SERVICE_ERROR = 'RESET_GET_SERVICE_PROVIDER_SERVICE_ERROR',
}

export interface GetServiceProviderServiceRequest extends GetServiceProviderServiceInput {
  type: GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_REQUEST;
}

export interface GetServiceProviderServiceSuccess {
  type: GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_SUCCESS;
  serviceProviderService: ServiceProviderService;
}

export interface GetServiceProviderServiceError {
  type: GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_ERROR;
  error: Error;
}

export interface ResetGetServiceProviderServiceError {
  type: GetServiceProviderServiceActionTypes.RESET_GET_SERVICE_PROVIDER_SERVICE_ERROR;
}
export interface ResetGetServiceProviderServiceState {
  type: GetServiceProviderServiceActionTypes.RESET_GET_SERVICE_PROVIDER_SERVICE_STATE;
}

export type GetServiceProviderServiceAction =
  | GetServiceProviderServiceRequest
  | GetServiceProviderServiceSuccess
  | GetServiceProviderServiceError
  | ResetGetServiceProviderServiceError
  | ResetGetServiceProviderServiceState;

export function getServiceProviderServiceRequest({
  serviceId,
  serviceProviderId,
}: GetServiceProviderServiceInput): GetServiceProviderServiceRequest {
  return {
    type: GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_REQUEST,
    serviceId,
    serviceProviderId,
  };
}

export function getServiceProviderServiceSuccess({
  serviceId,
  requiredFields,
}: ServiceProviderService): GetServiceProviderServiceSuccess {
  return {
    type: GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_SUCCESS,
    serviceProviderService: { serviceId, requiredFields },
  };
}

export function getServiceProviderServiceFailed(error: Error): GetServiceProviderServiceError {
  return {
    type: GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_ERROR,
    error: error,
  };
}

export function resetRetServiceProviderServiceState(): ResetGetServiceProviderServiceState {
  return {
    type: GetServiceProviderServiceActionTypes.RESET_GET_SERVICE_PROVIDER_SERVICE_STATE,
  };
}

export function resetRetServiceProviderServiceError(): ResetGetServiceProviderServiceError {
  return {
    type: GetServiceProviderServiceActionTypes.RESET_GET_SERVICE_PROVIDER_SERVICE_ERROR,
  };
}
