// Local dependencies
import { ServiceProviderService } from '../../../utils/types';
import { GetServiceProviderServiceAction, GetServiceProviderServiceActionTypes } from './actions';

interface ServiceProviderServiceStates {
  loading: boolean;
  isSuccess: boolean;
  error: Error;
  serviceProviderService: ServiceProviderService;
  isNotImplemented: boolean;
}

export const serviceProviderServiceInitialState: ServiceProviderServiceStates = {
  loading: false,
  isSuccess: false,
  error: null,
  serviceProviderService: null,
  isNotImplemented: false,
};

export default function getServiceProviderServiceReducer(
  state = serviceProviderServiceInitialState,
  action: GetServiceProviderServiceAction,
): ServiceProviderServiceStates {
  switch (action.type) {
    case GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_REQUEST: {
      return { ...state, loading: true };
    }

    case GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: true,
        isNotImplemented: false,
        serviceProviderService: action.serviceProviderService,
      };
    }

    case GetServiceProviderServiceActionTypes.GET_SERVICE_PROVIDER_SERVICE_ERROR: {
      if (action.error.message === 'Not implemented.') {
        return {
          ...state,
          loading: false,
          isNotImplemented: true,
          error: null,
        };
      }

      return { ...state, loading: false, error: action.error, isNotImplemented: false };
    }

    case GetServiceProviderServiceActionTypes.RESET_GET_SERVICE_PROVIDER_SERVICE_STATE: {
      return serviceProviderServiceInitialState;
    }

    case GetServiceProviderServiceActionTypes.RESET_GET_SERVICE_PROVIDER_SERVICE_ERROR: {
      return { ...state, error: null };
    }

    default: {
      return state;
    }
  }
}
