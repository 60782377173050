/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  NameRequiredExceptionInEnglish,
  NameRequiredExceptionInKyrgyz,
  NameRequiredExceptionInRussian,
  ProviderIdRequiredException,
  ProviderTypeRequiredException,
} from '../../utils/exceptions';
import { UpdateServiceProviderFields } from '../../utils/types';
import { CreateServiceProviderState } from '../createServiceProvider/redux/reducer';
import { UpdateServiceProviderState } from '../updateServiceProvider/redux/reducer';

export function validateServiceProvider(
  state: CreateServiceProviderState | UpdateServiceProviderState,
  fields: UpdateServiceProviderFields,
) {
  const { id, providerId, name_en, name_ky, name_ru, status } = fields;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (fields.hasOwnProperty('name_en')) {
    if (!name_en) {
      change.nameEnError = {
        $set: new NameRequiredExceptionInEnglish(),
      };
    } else {
      change['$unset'] = ['nameEnError'];
    }

    return update(state, {
      ...change,
      serviceProvider: {
        $set: update(state.serviceProvider, {
          name_en: { $set: name_en },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('status')) {
    return update(state, {
      ...change,
      serviceProvider: {
        $set: update(state.serviceProvider, {
          status: { $set: status },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('name_ky')) {
    if (!name_ky) {
      change.nameKyError = {
        $set: new NameRequiredExceptionInKyrgyz(),
      };
    } else {
      change['$unset'] = ['nameKyError'];
    }

    return update(state, {
      ...change,
      serviceProvider: {
        $set: update(state.serviceProvider, {
          name_ky: { $set: name_ky },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('name_ru')) {
    if (!name_ru) {
      change.nameRuError = {
        $set: new NameRequiredExceptionInRussian(),
      };
    } else {
      change['$unset'] = ['nameRuError'];
    }

    return update(state, {
      ...change,
      serviceProvider: {
        $set: update(state.serviceProvider, {
          name_ru: { $set: name_ru },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('providerId')) {
    if (!providerId) {
      change.providerIdError = {
        $set: new ProviderIdRequiredException(),
      };
    } else {
      change['$unset'] = ['providerIdError'];
    }

    return update(state, {
      ...change,
      serviceProvider: {
        $set: update(state.serviceProvider, {
          providerId: { $set: providerId },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('id')) {
    if (!id) {
      change.providerIdError = {
        $set: new ProviderTypeRequiredException(),
      };
    } else {
      change['$unset'] = ['id'];
    }

    return update(state, {
      ...change,
      serviceProvider: {
        $set: update(state.serviceProvider, {
          id: { $set: id },
        }),
      },
    });
  }

  return state;
}
