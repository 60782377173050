/* Local dependencies */
import { UIException } from '../../../../../redux/exceptions';
import { ServiceProvider } from '../../../utils/types';
import { ServiceProviderStatus } from '../../utils/types';

export enum ListServiceProvidersActionTypes {
  LIST_SERVICE_PROVIDERS_REQUEST = 'LIST_SERVICE_PROVIDERS_REQUEST',
  LIST_SERVICE_PROVIDERS_SUCCESS = 'LIST_SERVICE_PROVIDERS_SUCCESS',
  LIST_SERVICE_PROVIDERS_ERROR = 'LIST_SERVICE_PROVIDERS_ERROR',
  RESET_SELECTED_SERVICE_PROVIDER = 'RESET_SELECTED_SERVICE_PROVIDER',
  SELECT_SERVICE_PROVIDER_LIST = 'SELECT_SERVICE_PROVIDER_LIST',
  RESET_LIST_SERVICE_PROVIDERS_STATE = 'RESET_LIST_SERVICE_PROVIDERS_STATE',
  RESET_LIST_SERVICE_PROVIDERS_ERROR = 'RESET_LIST_SERVICE_PROVIDERS_ERROR',
}

export interface ListServiceProvidersFitler {
  statuses?: ServiceProviderStatus[];
}

export interface ListServiceProviders {
  type: ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_REQUEST;
  currentPage?: number;
  searchString?: string;
  filter?: ListServiceProvidersFitler;
  size?: number;
}

export interface ListServiceProvidersArguments extends Omit<ListServiceProviders, 'type'> {}

export interface ListServiceProvidersSuccess {
  type: ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_SUCCESS;
  serviceProviders: ServiceProvider[];
  total: number;
}

export interface ListServiceProvidersError {
  type: ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_ERROR;
  error: UIException;
}

export interface SelectServiceProvider {
  type: ListServiceProvidersActionTypes.SELECT_SERVICE_PROVIDER_LIST;
  serviceProvider: ServiceProvider;
}

export interface ResetSelectedServiceProvider {
  type: ListServiceProvidersActionTypes.RESET_SELECTED_SERVICE_PROVIDER;
}

export interface ResetServiceProvidersState {
  type: ListServiceProvidersActionTypes.RESET_LIST_SERVICE_PROVIDERS_STATE;
}
export interface ResetServiceProvidersError {
  type: ListServiceProvidersActionTypes.RESET_LIST_SERVICE_PROVIDERS_ERROR;
}

export type ListServiceProvidersAction =
  | ListServiceProviders
  | ListServiceProvidersSuccess
  | ListServiceProvidersError
  | SelectServiceProvider
  | ResetSelectedServiceProvider
  | ResetServiceProvidersState
  | ResetServiceProvidersError;

export function listServiceProviders({
  currentPage = 1,
  searchString = '',
  size = 20,
  filter = {},
}: ListServiceProvidersArguments): ListServiceProviders {
  return {
    type: ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_REQUEST,
    currentPage,
    filter,
    searchString,
    size,
  };
}

export function listServiceProvidersSucceeded(total, serviceProviders): ListServiceProvidersSuccess {
  return {
    type: ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_SUCCESS,
    serviceProviders,
    total,
  };
}

export function listServiceProvidersFailed(error: UIException): ListServiceProvidersError {
  return {
    type: ListServiceProvidersActionTypes.LIST_SERVICE_PROVIDERS_ERROR,
    error,
  };
}

export function selectServiceProvider(serviceProvider: ServiceProvider): SelectServiceProvider {
  return {
    type: ListServiceProvidersActionTypes.SELECT_SERVICE_PROVIDER_LIST,
    serviceProvider,
  };
}

export function resetSelectedServiceProvider(): ResetSelectedServiceProvider {
  return {
    type: ListServiceProvidersActionTypes.RESET_SELECTED_SERVICE_PROVIDER,
  };
}

export function resetListServiceProvidersState(): ResetServiceProvidersState {
  return {
    type: ListServiceProvidersActionTypes.RESET_LIST_SERVICE_PROVIDERS_STATE,
  };
}

export function resetListServiceProvidersError(): ResetServiceProvidersError {
  return {
    type: ListServiceProvidersActionTypes.RESET_LIST_SERVICE_PROVIDERS_ERROR,
  };
}
