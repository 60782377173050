/* External dependencies */
import gql from 'graphql-tag';

export const createServiceProviderMutation = gql`
  mutation createServiceProvider($input: CreateServiceProviderInput!) {
    createServiceProvider(input: $input) {
      id
      providerId
      name_en
      name_ky
      name_ru
    }
  }
`;
