/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { User } from '../../userTypes';
import { ListUsersAction, ListUsersActionTypes } from './actions';

export interface UsersState {
  currentPage: number;
  error?: Error;
  loading?: boolean;
  searchString: string;
  size: number;
  total: number;
  users: User[];
}

export const initialUsersState: UsersState = {
  currentPage: 1,
  searchString: '',
  size: 20,
  total: 0,
  users: [],
};

export default function usersReducer(state = initialUsersState, action: ListUsersAction) {
  switch (action.type) {
    case ListUsersActionTypes.LIST_USERS_REQUEST:
      const { currentPage, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
      });

    case ListUsersActionTypes.LIST_USERS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        total: { $set: action.total },
        users: { $set: action.users },
      });

    case ListUsersActionTypes.LIST_USERS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListUsersActionTypes.LIST_USERS_ERROR_RESET:
      return update(state, { $unset: ['error'] });

    default:
      return state;
  }
}
