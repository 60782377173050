/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class MaxAmountRequiredException extends UIException {
  code = 'MaxAmountRequiredException';
}

export class AmountRequiredException extends UIException {
  code = 'AmountRequiredException';
}

export class MinAmountRequiredException extends UIException {
  code = 'MinAmountRequiredException';
}

export class StatusMessageRequiredException extends UIException {
  code = 'StatusMessageRequiredException';
}

export class AmountServiceFeesRequiredException extends UIException {
  code = 'AmountServiceFeesRequiredException';
}

export class InvalidNumberLessThanZeroRequiredException extends UIException {
  code = 'InvalidNumberLessThanZeroRequiredException';
}

export class IDRequiredException extends UIException {
  code = 'IDRequiredException';
}

export class LogoRequiredExceptionInRussian extends UIException {
  code = 'LogoRequiredExceptionInRussian';
}

export class NameRequiredException extends UIException {
  code = 'NameRequiredException';
}

export class RequiredException extends UIException {
  code = 'RequiredException';
}

export class NameRequiredExceptionInEnglish extends UIException {
  code = 'NameRequiredExceptionInEnglish';
}

export class NameRequiredExceptionInRussian extends UIException {
  code = 'NameRequiredExceptionInRussian';
}

export class NameRequiredExceptionInKyrgyz extends UIException {
  code = 'NameRequiredExceptionInKyrgyz';
}

export class ServerRequiredException extends UIException {
  code = 'ServerRequiredException';
}

export class ServiceProviderRequiredException extends UIException {
  code = 'ServiceProviderRequiredException';
}

export class StatusRequiredException extends UIException {
  code = 'StatusRequiredException';
}

export class CurrencyRequiredException extends UIException {
  code = 'CurrencyRequiredException';
}

export class FlatFeePercentRequiredException extends UIException {
  code = 'FlatFeePercentRequiredException';
}

export class LogoRequiredException extends UIException {
  code = 'LogoRequiredException';
}

export class PortRequiredException extends UIException {
  code = 'PortRequiredException';
}

export class PopularityScoreRequiredException extends UIException {
  code = 'PopularityScoreRequiredException';
}

export class IpRequiredException extends UIException {
  code = 'IpRequiredException';
}

export class AccountIdRequiredException extends UIException {
  code = 'AccountIdRequiredException';
}

export class ServiceNameFieldRequiredException extends UIException {
  code = 'ServiceNameFieldRequiredException';
}

export class FormatRequiredException extends UIException {
  code = 'FormatRequiredException';
}

export class MethodRequiredException extends UIException {
  code = 'MethodRequiredException';
}

export class ProtocolRequiredException extends UIException {
  code = 'ProtocolRequiredException';
}

export class UsernameRequiredException extends UIException {
  code = 'UsernameRequiredException';
}

export class PasswordRequiredException extends UIException {
  code = 'PasswordRequiredException';
}

export class ParentIdRequiredException extends UIException {
  code = 'ParentIdRequiredException';
}

export class EmptyRequiredException extends UIException {
  code = 'LabelRequiredException';
}

export class LabelRequiredException extends UIException {
  code = 'LabelRequiredException';
}

export class LabelRequiredExceptionInEnglish extends UIException {
  code = 'LabelRequiredExceptionInEnglish';
}

export class DynamicsFeeStepsRequiredException extends UIException {
  code = 'DynamicsFeeStepsRequiredException';
}

export class KeyboardTypeRequiredException extends UIException {
  code = 'selectKeyBoardType';
}
export class IdRequiredException extends UIException {
  code = 'IdRequiredException';
}

export class ValueRequiredException extends UIException {
  code = 'ValueRequiredException';
}

export class UserNameRequiredException extends UIException {
  code = 'userFirstNameRequiredFields';
}

export class UserLastNameRequiredException extends UIException {
  code = 'userLastNameRequiredFields';
}

export class UserBirthDayRequiredException extends UIException {
  code = 'userBirthDateRequiredFields';
}

export class UserIDRequiredException extends UIException {
  code = 'userIDNumberRequiredFields';
}

export class UserIDRequiredFieldsException extends UIException {
  code = 'requiredIDNumber';
}

export class UserPinRequiredFieldsException extends UIException {
  code = 'userINNRequiredFields';
}

export class UserPhoneRequiredFieldsException extends UIException {
  code = 'userPhoneNumberRequiredFields';
}

export class UserFrontImageRequiredException extends UIException {
  code = 'userFrontImageRequiredException';
}

export class UserBackImageRequiredException extends UIException {
  code = 'userBackImageRequiredException';
}

export class UserSelfieImageRequiredException extends UIException {
  code = 'userSelfieImageRequiredException';
}

export class UserPatronymicRequiredException extends UIException {
  code = 'userPatronymicRequiredException';
}

export class UserGenderRequiredException extends UIException {
  code = 'userGenderRequiredException';
}

export class ProviderIdRequiredException extends UIException {
  code = 'ProviderIdRequiredException';
}

export class ProviderTypeRequiredException extends UIException {
  code = 'ProviderTypeRequiredException';
}

export type ErrorRequiredExceptionTypes =
  | AmountRequiredException
  | StatusMessageRequiredException
  | AmountServiceFeesRequiredException
  | NameRequiredException
  | NameRequiredExceptionInEnglish
  | StatusRequiredException
  | CurrencyRequiredException
  | FlatFeePercentRequiredException
  | ServiceNameFieldRequiredException
  | EmptyRequiredException
  | LabelRequiredException
  | LabelRequiredExceptionInEnglish
  | DynamicsFeeStepsRequiredException
  | IdRequiredException
  | ValueRequiredException
  | ProviderIdRequiredException
  | ProviderTypeRequiredException;
